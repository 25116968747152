#ourwork {
  opacity: 0;
}

.ourwork {
}

.slid {
  display: block;
  width: 80% !important;
  background-color: red;
  display: flex;
  flex-direction: column;
  height: 200px;
}

.revert {
  transform: rotate(90deg);
}

.revert img {
  width: 300px;
}

.car {
  width: 70%;
  position: absolute;
  left: 0;
}

.ParallaxVideo {
  height: 100vh;
}

.ParallaxVideo video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.ParallaxMainContent {
  background-color: rgb(11, 61, 90);
  color: #b8c1ec;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  height: 100vh;
  position: relative;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    height: 500px;
    flex-direction: column;
    align-items: center;
    padding: 0;
    background-color: rgb(11, 61, 90);
  }
}

.white-mask {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  z-index: 200;

  background: -webkit-linear-gradient(rgba(11, 61, 90, 1) 0, rgba(11, 61, 90, 0) 100%);
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.videos-header {
  background: rgb(11, 61, 90);

  width: 100%;
  height: 300px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 200px;
  }
}

.videos-header-title {
  font-size: 40px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: "Segoe UI Semibold";
}
.videos-header-description {
  font-size: 20px;
  font-weight: 400;

  color: white;
  font-family: "Segoe UI Light";
  @media screen and (max-width: 768px) {
    width: 90%;
    text-align: center;
    height: auto;
  }
}

.our-partners {
  background-color: white;

  text-align: center;
  .our-partners-header {
    background: rgb(11, 61, 90);
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }

  .our-partners-title {
    font-size: 40px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: "Segoe UI Semibold";
  }

  .logos-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 100px;
  }

  .logo-wrapper {
    width: 19%;
    margin-bottom: 2em;

    .logo-img {
      border-radius: 0;
      display: block;
      //width: 70%;
      margin: 0 auto;
      filter: grayscale(100%);
      opacity: 0.6;
      cursor: pointer;
      transition: all 0.1s linear;
      object-fit: contain;
      &:hover {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }

  .mobile-ourwork-logos {
    //background-color: rgba(128, 128, 128, 0.178);
  }

  .mobile-ourwork-logos-list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    height: 300px;
  }

  .slider-wrapper {
  }

  .control-dots {
  }

  .carousel-slider {
    // padding-bottom: 80px !important;
    background-color: rgba(128, 128, 128, 0.178) !important;
  }

  .dot {
    background-color: black !important;
    width: 15px !important;
    height: 15px !important;
  }

  .mobile-ourwork-logo {
    width: 40%;
    .mobile-ourwork-logo-image {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: contain;
      filter: grayscale(100%);
      opacity: 0.6;
      &:hover {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
}

.video-item-page {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 50px;
  @media screen and (max-width: 481px) {
    padding: 0 5px;
  }
}

.video-block {
  width: 100%;
  display: flex;
  @media screen and (max-width: 481px) {
    flex-direction: column;
    height: 80%;
  }
}

.video-card {
  pointer-events: none;
  width: 70%;

  background-color: white;
  border-radius: 20px;
  color: black;
  display: flex;

  font-size: 30px;
  color: tomato;
  padding: 3px;
  margin-right: 75px;
  @media screen and (max-width: 481px) {
    width: 100%;
    margin-right: 0;
    height: 80%;
  }
}

.single-video-item {
  border-radius: 15px;
  pointer-events: none;
  width: 100%;
  object-fit: cover;
}

.video-info {
  width: 28%;
  color: white;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 481px) {
    width: 100%;
    text-align: center;
  }

  justify-content: flex-start;
  .video-title {
    font-size: 32px;
    font-family: "Segoe UI Bold";
    margin-bottom: 20px;
  }

  .video-description {
    font-size: 24px;
    width: 80%;
    @media screen and (max-width: 481px) {
      font-size: 16px;
      width: 100%;
    }
  }
}
