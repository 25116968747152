@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?8cce9u");
  src: url("fonts/icomoon.eot?8cce9u#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?8cce9u") format("truetype"), url("fonts/icomoon.woff?8cce9u") format("woff"),
    url("fonts/icomoon.svg?8cce9u#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-d_animation:before {
  content: "\e900";
}
.icon-d_visualization:before {
  content: "\e901";
}
.icon-academycertificatepictogram:before {
  content: "\e902";
}
.icon-academypictogram-01:before {
  content: "\e903";
}
.icon-blockchainpictogramfor-r-and-d:before {
  content: "\e904";
}
.icon-consulting:before {
  content: "\e905";
}
.icon-custom_made_ar_and_vr_solutions:before {
  content: "\e906";
}
.icon-cybersecurity_services:before {
  content: "\e907";
}
.icon-digital_marketing:before {
  content: "\e908";
}
.icon-ditigal_transformation:before {
  content: "\e909";
}
.icon-game_development:before {
  content: "\e90a";
}
.icon-gamingpictogramfor-r-and-d:before {
  content: "\e90b";
}
.icon-graphic_design:before {
  content: "\e90c";
}
.icon-managed_service:before {
  content: "\e90d";
}
.icon-mision:before {
  content: "\e90e";
}
.icon-mixed_reality:before {
  content: "\e90f";
}
.icon-ourorganization-1:before {
  content: "\e910";
}
.icon-ourorganization:before {
  content: "\e911";
}
.icon-professional_services:before {
  content: "\e912";
}
.icon-projects:before {
  content: "\e913";
}
.icon-software_engineering:before {
  content: "\e914";
}
.icon-strategy:before {
  content: "\e915";
}
.icon-vision:before {
  content: "\e916";
}
.icon-web_development:before {
  content: "\e917";
}
