* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.marquee {
  width: 50vh;
  height: 100vh;
  background-color: rgb(11, 61, 90);

  overflow: hidden;

  padding: 0 15px;

  @media screen and (max-width: 768px) {
    background-color: rgb(11, 61, 90);
    width: 100vw;
    height: 33%;
    padding: 15px 0;
  }
}
.marquee-content {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    height: 100%;
  }
}

@keyframes scrolling {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-365vh);
  }
}

@keyframes scrollingHorisontal {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-4500px);
  }
}

.marquee-content li {
  height: 70vh;

  flex-shrink: 0;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 3vh;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0 10px;
  background: white;
  transition: padding 0.1s ease-in;
  @media screen and (max-width: 768px) {
  }
}

.marquee-content li:hover {
  padding: 0;
}

// li img {
//   width: 100%;
//   height: 100%;
//   display: block;
//   object-fit: fill;
//   border-radius: 20px;
//   @media screen and (max-width: 768px) {
//     object-fit: fill;
//     height: 100%;
//   }
// }

.mobile-marquee {
  width: 100vw;
  height: 100%;
  padding: 30px 0;
  background-color: rgb(11, 61, 90);
  overflow: hidden;
}

.mobile-marquee-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  list-style: none;
  li {
    width: 280px;
    flex-shrink: 0;

    margin-right: 20px;
    height: 100%;
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 0 10px;
    background: white;
    transition: padding 0.1s ease-in;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

#slider1 {
  animation: scrolling 29s linear infinite;
  @media screen and (max-width: 768px) {
    animation: scrollingHorisontal 14s linear infinite;
  }
}

#slider2 {
  animation: scrolling 24s linear infinite;
  @media screen and (max-width: 768px) {
    animation: scrollingHorisontal 14s linear infinite;
  }
}
#slider3 {
  animation: scrolling 36s linear infinite;
  @media screen and (max-width: 768px) {
    animation: scrollingHorisontal 14s linear infinite;
  }
}

#slider1:hover {
  animation-play-state: paused;
}
#slider2:hover {
  animation-play-state: paused;
}
#slider3:hover {
  animation-play-state: paused;
}

#mobile-slider {
  animation: scrollingHorisontal 20s linear infinite;
}
