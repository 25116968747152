#who-we-are {
  transition: visibility 1s, opacity 1s linear;

  opacity: 0;
  // //perspective: 100em;
  // // overflow: scroll;
  // background: black;
  // width: 100%;
  // height: 100%;
  // //  position: relative;
  // overflow: scroll;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  #fake-fadein {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
  }
  .whoweare-cards {
    background: black;
    position: relative;
    // // left: 0;
    // // right: 0;
    // // top: 0;
    // // bottom: 0;
    // background-color: black;
    width: 100vw;
    height: 100vh;
  }

  .who-we-are-block {
    position: absolute;
    left: 13%;
    top: 0;
    width: 30%;
    max-width: 500px;
    color: white;
    background: rgba(255, 255, 255, 0.199);
    bottom: calc(100vh - 600px - 2vh - (80px + 20vw / 4));
    // height: calc(100vh - 680px - 6vh - (20vw / 4));
    border: 2px solid white;
    padding: 20px;
    transition: transform 0.3s linear;
    user-select: none;
    transform-style: preserve-3d;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      bottom: 0;
    }

    @media screen and (min-width: 1660px) {
      left: calc((100vw - 1040px) / 2);
    }

    @media screen and (min-width: 1250px) {
      left: calc((100vw - 550px - 30%) / 2);
    }

    @media screen and (max-width: 1024px) {
      //left: 10%;
    }

    // @media screen and (max-width: 768px) {
    //   width: calc(100vw - 6vh);

    //   top: 0;
    //   max-width: calc(100vw - 8vh);
    //   left: 4vh;
    //   right: 4vh;
    //   font-size: 13px;
    //   padding: 1.5vh;
    //   height: 25vh;
    //   padding: 1vh;
    // }

    @media screen and (max-width: 600px) {
      width: calc(100vw - 6vh);

      top: 0;
      max-width: calc(100vw - 8vh);
      left: 4vh;
      right: 4vh;
      font-size: 13px;
      padding: 1.5vh;
      height: 25vh;
      padding: 1vh;
    }

    .who-we-are-block-title {
      pointer-events: none;
      width: 100%;
      text-align: left;

      text-transform: uppercase;
      font-weight: bold;
      font-size: 32px;
      font-family: "Segoe UI Bold";
      @media screen and (min-width: 1600px) {
        font-size: 40px;
      }

      @media screen and (max-width: 1180px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 28px;
      }

      @media screen and (max-width: 768px) {
        font-size: 24px;
      }

      @media screen and (max-width: 481px) {
        font-size: 20px;
      }
    }

    .who-we-are-block-description {
      margin-top: 20px;
      pointer-events: none;
      font-size: 22px;
      font-family: "Segoe UI Light";
      line-height: 2;
      text-align: left;

      @media screen and (min-width: 1600px) {
        font-size: 24px;
      }

      @media screen and (max-width: 1180px) {
        margin-top: 10px;
        font-size: 19px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 18px;
        margin-top: 5px;
      }

      @media screen and (max-width: 768px) {
        font-size: 15px;
        margin-top: 5px;
      }

      @media screen and (max-width: 600px) {
        font-size: 14px;
        margin-top: 5px;
      }

      @media screen and (max-width: 481px) {
        font-size: calc(25vh / 14);
        line-height: normal;
      }
    }
  }
}

.who-we-are {
  // visibility: hidden;
  // opacity: 0;
  // transition: visibility 0s, opacity 1s linear;
  color: white;

  .whoweare-cards {
    width: 100%;
    height: 100vh;
    background: black;
    // // left: 0;
    // // right: 0;
    // // top: 0;
    // // bottom: 0;
    // background-color: black;
    // overflow-y: scroll;
  }

  .who-we-are-desc {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    width: 40vw;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 1s linear;
  }

  .right {
    right: 100px;
  }

  .left {
    left: 70px;
  }

  .our-organization-back-button {
    position: fixed;
    bottom: 50px;
    left: 50px;
    color: white;
  }

  .who-we-are-mobile {
    display: none;
    color: white;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 25%;
    width: 100%;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 1s linear;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
      font-family: "Segoe UI Bold";
    }

    p {
      font-size: 16px;
      font-family: "Segoe UI Light";
      width: 80%;
    }
  }

  .active {
    display: flex;
  }

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -10;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .video-background {
      z-index: -10;
      position: absolute;
      height: 100vh;
      width: 100vw;
      object-fit: cover;
      overflow: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .video-background-mobile {
      z-index: -10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100vh;
      width: auto;
      overflow: hidden;
    }
  }

  .desktop {
    width: calc(100vh * 1.77777777777777);
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .mobile {
    width: 100%;
    height: 100vw;
    top: 40%;
    transform: translate(0, -50%);
  }

  .png-player {
    position: absolute;
    overflow: hidden;
    pointer-events: none;

    .animation {
      position: absolute;
      display: none;
    }
  }

  .compass-animation {
    animation: compass-animation-X 1s steps(24) 4, compass-animation-Y 4s steps(1) 1;
    animation-fill-mode: both;
    position: absolute;
    height: 400vh;
  }

  .compass-animation-r {
    animation: compass-animation-X-r 0.75s steps(24) 2, compass-animation-Y-r 1.5s steps(1) 1;
    animation-fill-mode: both;
    position: absolute;
    height: 400vh;
  }

  .revert-anim {
    animation-direction: reverse;
  }

  @keyframes compass-animation-X {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-96%);
    }
  }

  @keyframes compass-animation-Y {
    0% {
      top: 0%;
    }

    25% {
      top: -100%;
    }

    50% {
      top: -200%;
    }

    75% {
      top: -300%;
    }

    100% {
      top: -300%;
    }
  }

  @keyframes compass-animation-X-r {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-96%);
    }
  }

  @keyframes compass-animation-Y-r {
    0% {
      top: 0%;
    }

    50% {
      top: -100%;
    }

    100% {
      top: -100%;
    }
  }

  .visible {
    display: block !important;
  }
}
