#selection {
  opacity: 0;
  transition: opacity 1.2s linear;
  #selection-content {
    .home-btns {
      position: absolute;
      top: 35%;

      left: 5%;
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        top: 17%;
      }
      @media screen and (max-width: 600px) {
        left: 6vw;
        top: calc(13vw + 100px);
        //padding-left: 6vw;
      }

      @media screen and (max-height: 480px) {
        top: 12vw;
      }

      .home-btn {
        text-transform: uppercase;
        cursor: pointer;
        display: block;
        font-size: 40px;
        font-family: "Segoe UI Bold";
        font-weight: 500;
        opacity: 0.3;
        color: white;
        background-color: transparent;
        border: 0;
        transition: all 0.09s ease-in;
        transform-origin: left;

        @media only screen and (max-width: 481px) {
          margin: 0;
          font-size: 22px;
          margin-bottom: 1vw;
        }

        @media only screen and (max-height: 481px) {
          font-size: 24px;
        }
      }
    }
    .home-btn-active {
      transform: scale(115%);
      opacity: 1 !important;
      cursor: pointer;
      transition: transform 0.5s;
      color: white !important;
    }
  }

  .frontpagetext {
    position: absolute;
    top: -20px;
    left: 10px;
    color: white;
    display: flex;
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s linear;
  }

  .home-btn-mobile-wrapper {
    width: 100%;
    position: absolute;
    top: 23vh;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .frontpagetext {
      position: unset;
    }
    .home-btn {
      position: unset;
      color: white;
    }
  }

  .swipe-icon-wrapper {
    width: 100%;
    height: 150px;
    position: fixed;
    right: 0;
    bottom: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: white;
    align-items: center;
    z-index: 0;

    p {
      position: fixed;
      bottom: 60px;
      width: 100%;
      text-align: center;
      font-size: 16px;
    }

    img {
      width: 100px;
    }
  }
}
