.dragger-area {
  position: absolute;
  top: calc(80px + 20vw / 4);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;

  perspective: 100em;
  font-family: "Segoe UI Regular";
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    top: calc((100vh - 600px - 2vh) / 2);
    bottom: calc((100vh - 600px - 2vh) / 2);
  }
  @media screen and (max-width: 600px) {
    top: calc(40% / 4);
    //margin-top: 100px;
    left: 0;
    right: 0;

    //height: 90vh;
  }
}

.dragger-element {
  font-size: 20px;
  position: absolute;
  height: 200px;
  right: 12%;
  border: 2px solid white;
  top: 0;
  width: 40%;
  max-width: 500px;
  transition: transform 0.3s linear;
  padding: 20px;
  user-select: none;
  background: rgba(255, 255, 255, 0.199);

  transform-style: preserve-3d;
  @media screen and (min-width: 1660px) {
    right: calc((100vw - 1040px) / 2);
    font-size: 22px;
  }

  @media screen and (min-width: 1250px) {
    right: calc((100vw - 530px - 30%) / 2);
  }

  @media screen and (max-width: 1180px) {
    font-size: 17px;
    //right: 12%;
  }

  @media screen and (max-width: 1024px) {
    font-size: 15px;
    // right: 12%;
  }

  // @media screen and (max-width: 768px) {
  //   width: calc(100vw - 8vh);
  //   max-width: calc(100vw - 20vh);
  //   left: 10vh;
  //   right: 10vh;
  //   font-size: 13px;
  //   padding: 1.5vh;
  // }

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 8vh);
    width: calc(100vw - 8vh);
    left: 4vh;
    right: 4vh;
    top: calc(25vh + 1vh);
    height: 18vh;
    font-size: 15px;
    padding: 1vh;
  }

  @media screen and (max-width: 481px) {
    font-size: calc(18vh / 10);
  }

  h1,
  p {
    pointer-events: none;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    background-color: transparent;
  }
  &:hover {
  }
}

.dragger-element1 {
  font-size: 20px;
  user-select: none;
  position: absolute;
  transition: transform 0.3s linear;
  height: 200px;
  right: 12%;
  top: calc(200px + 1vh);
  background: rgba(255, 255, 255, 0.199);
  border: 2px solid white;
  width: 40%;
  max-width: 500px;
  padding: 20px;
  transform-style: preserve-3d;

  @media screen and (min-width: 1660px) {
    right: calc((100vw - 1040px) / 2);
    font-size: 22px;
  }

  @media screen and (min-width: 1250px) {
    right: calc((100vw - 530px - 30%) / 2);
  }

  @media screen and (max-width: 1180px) {
    //right: 12%;
    font-size: 17px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }

  // @media screen and (max-width: 768px) {
  //   width: calc(100vw - 8vh);
  //   max-width: calc(100vw - 20vh);
  //   left: 10vh;
  //   right: 10vh;
  //   font-size: 13px;
  //   padding: 1.5vh;
  //   height: 18vh;
  // }

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 8vh);
    width: calc(100vw - 8vh);
    left: 4vh;
    right: 4vh;
    top: calc(25vh + 18vh + 2vh);
    font-size: 15px;
    padding: 1vh;
    height: 18vh;
  }

  @media screen and (max-width: 481px) {
    font-size: calc(18vh / 10);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    background-color: transparent;
  }
  &:hover {
    // border: 3px solid white;
    // background-color: rgba(255, 255, 255, 0.5);
  }
}

.dragger-element2 {
  font-size: 20px;
  user-select: none;
  position: fixed;
  transition: transform 0.3s linear;
  height: 200px;
  right: 12%;
  top: calc(400px + 2vh);
  background: rgba(255, 255, 255, 0.199);
  border: 2px solid white;
  width: 40%;
  max-width: 500px;
  padding: 20px;
  transform-style: preserve-3d;

  @media screen and (min-width: 1660px) {
    right: calc((100vw - 1040px) / 2);
    font-size: 22px;
  }

  @media screen and (min-width: 1250px) {
    right: calc((100vw - 530px - 30%) / 2);
  }

  @media screen and (max-width: 1180px) {
    // right: 12%;
    font-size: 17px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }

  // @media screen and (max-width: 768px) {
  //   width: calc(100vw - 8vh);
  //   max-width: calc(100vw - 20vh);
  //   left: 10vh;
  //   right: 10vh;
  //   font-size: 13px;
  //   padding: 1vh;
  //   height: 18vh;
  // }

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 8vh);
    width: calc(100vw - 8vh);
    left: 4vh;
    right: 4vh;
    top: calc(25vh + 18vh + 18vh + 3vh);
    font-size: 15px;
    padding: 1vh;
    height: 18vh;
  }

  @media screen and (max-width: 481px) {
    font-size: calc(18vh / 10);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    background-color: transparent;
  }
  &:hover {
    // border: 3px solid white;
    // background-color: rgba(255, 255, 255, 0.5);
  }
}

.card-title {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  margin-bottom: 10px;
  @media screen and (max-width: 481px) {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

.glass {
  background: rgba(0, 0, 0, 0.509) !important;
  border: none !important;
  transform: rotateY(30deg);
}

.layers {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -100;
  pointer-events: none;

  .layer {
    //border: 2px solid white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2) !important;

    //box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    //transform: translateZ(-300px);
    &:nth-child(1) {
      transform: translateZ(-4px);
    }
    &:nth-child(2) {
      transform: translateZ(-8px);
    }
    &:nth-child(3) {
      transform: translateZ(-12px);
    }
    &:nth-child(4) {
      transform: translateZ(-16px);
    }
    &:nth-child(5) {
      transform: translateZ(-20px);
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    -webkit-overflow-scrolling: touch; /* Safari 3.1+ */
    -moz-overflow-scrolling: touch; /* Firefox 2+ */
    -ms-overflow-scrolling: touch; /* IE 10+ */
    overflow-scrolling: touch; /* Standard syntax */
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
}
