.newAcademy-texts {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;
	z-index: 50;

	.newAcademy-box {
		width: 100%;
		max-width: 400px;
		// adjust height as needed
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.newAcademy-paragraph {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
	}
}

@media (min-width: 768px) {

	/* styles for larger screens */
	.newAcademy-texts {
		/* adjust positioning as needed */
		position: absolute;
		top: -20%;
		bottom: 0;
		left: 0;
		right: 0;
		pointer-events: none;
		z-index: 50;

		.newAcademy-box {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.newAcademy-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 17px;
			pointer-events: none;
		}
	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 

// .academyNewN-texts {
// 	position: relative;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	background-color: transparent;
// 	color: white;
// 	padding: 10px;
// 	top: 400px;
// 	pointer-events: none;
// 	z-index: 50;

// 	.academyNewN-box {
// 		width: 100%;
// 		max-width: 400px;
// 		// adjust height as needed
// 		height: auto;
// 		transition: height 0.1s linear;
// 		pointer-events: none;
// 	}

// 	.academyNewN-paragraph {
// 		font-family: "Segoe_UI_Semibold";
// 		margin-bottom: 10px;
// 		font-size: 18px;
// 		pointer-events: none;
// 		white-space: nowrap;
// 	}
// }

// @media (min-width: 768px) {

// 	.academyNewN-texts {
// 		/* adjust positioning as needed */
// 		position: absolute;
// 		top: 0;
// 		bottom: 0;
// 		left: -35%;
// 		right: 0;
// 		pointer-events: none;
// 		z-index: 50;

// 		.academyNewN-box {
// 			width: 100%;
// 			max-width: 46%;
// 			// adjust height as needed
// 			height: auto;
// 			transition: height 0.1s linear;
// 			pointer-events: none;
// 		}

// 		.academyNewN-paragraph {
// 			font-family: "Segoe_UI_Semibold";
// 			margin-bottom: 10px;
// 			font-size: 36px;
// 			pointer-events: none;
// 			white-space: nowrap;
// 		}

// 	}

// 	.scale {
// 		animation: scale linear 0.5s forwards;
// 	}

// 	.unscale {
// 		animation: unscale linear 0.5s forwards;
// 	}

// 	@keyframes scale {
// 		0% {
// 			width: 100px;
// 		}

// 		100% {
// 			width: 600px;
// 		}
// 	}

// 	@keyframes unscale {
// 		0% {
// 			width: 600px;
// 		}

// 		100% {
// 			width: 100px;
// 		}
// 	}
// }

// .academyNewN-texts2 {
// 	position: relative;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	background-color: transparent;
// 	color: white;
// 	padding: 10px;
// 	top: 400px;
// 	pointer-events: none;
// 	z-index: 50;

// 	.academyNewN-box2 {
// 		width: 100%;
// 		max-width: 400px;
// 		// adjust height as needed
// 		height: auto;
// 		transition: height 0.1s linear;
// 		pointer-events: none;
// 	}

// 	.academyNewN-paragraph2 {
// 		font-family: "Segoe_UI_Semibold";
// 		margin-bottom: 10px;
// 		font-size: 18px;
// 		pointer-events: none;
// 		white-space: nowrap;
// 	}
// }

// @media (min-width: 768px) {

// 	.academyNewN-texts2 {
// 		position: absolute;
// 		top: -43%;
// 		bottom: 0;
// 		left: -38%;
// 		right: 0;
// 		pointer-events: none;


// 		.academyNewN-box2 {
// 			width: 100%;
// 			max-width: 40%;
// 			height: auto;
// 			transition: height 0.1s linear;
// 			pointer-events: none;
// 			z-index: 50;
// 		}

// 		.academyNewN-paragraph2 {
// 			font-family: "Segoe_UI_Semibold";
// 			margin-bottom: 10px;
// 			font-size: 34px;
// 			pointer-events: none;
// 			white-space: nowrap;
// 		}

// 	}

// 	.scale {
// 		animation: scale linear 0.5s forwards;
// 	}

// 	.unscale {
// 		animation: unscale linear 0.5s forwards;
// 	}

// 	@keyframes scale {
// 		0% {
// 			width: 100px;
// 		}

// 		100% {
// 			width: 600px;
// 		}
// 	}

// 	@keyframes unscale {
// 		0% {
// 			width: 600px;
// 		}

// 		100% {
// 			width: 100px;
// 		}
// 	}
// }


// .academyNewN-texts3 {
// 	position: relative;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	background-color: transparent;
// 	color: white;
// 	padding: 10px;
// 	top: 400px;
// 	pointer-events: none;
// 	z-index: 50;

// 	.academyNewN-box3 {
// 		width: 100%;
// 		max-width: 400px;
// 		// adjust height as needed
// 		height: auto;
// 		transition: height 0.1s linear;
// 		pointer-events: none;
// 	}

// 	.academyNewN-paragraph3 {
// 		font-family: "Segoe_UI_Semibold";
// 		margin-bottom: 10px;
// 		font-size: 18px;
// 		pointer-events: none;
// 		white-space: nowrap;
// 	}
// }

// @media (min-width: 768px) {

// 	.academyNewN-texts3 {
// 		/* adjust positioning as needed */
// 		position: absolute;
// 		top: -25%;
// 		bottom: 0;
// 		left: -18%;
// 		right: 0;
// 		pointer-events: none;
// 		z-index: 50;

// 		.academyNewN-box3 {
// 			width: 100%;
// 			max-width: 55%;
// 			// adjust height as needed
// 			height: auto;
// 			transition: height 0.1s linear;
// 			pointer-events: none;
// 		}

// 		.academyNewN-paragraph3 {
// 			font-family: "Segoe_UI_Semibold";
// 			margin-bottom: 10px;
// 			font-size: 37px;
// 			pointer-events: none;
// 			white-space: nowrap;
// 		}

// 	}

// 	.scale {
// 		animation: scale linear 0.5s forwards;
// 	}

// 	.unscale {
// 		animation: unscale linear 0.5s forwards;
// 	}

// 	@keyframes scale {
// 		0% {
// 			width: 100px;
// 		}

// 		100% {
// 			width: 600px;
// 		}
// 	}

// 	@keyframes unscale {
// 		0% {
// 			width: 600px;
// 		}

// 		100% {
// 			width: 100px;
// 		}
// 	}
// }

//Landscape
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (orientation: landscape) {
	.academyNewN-mother {
		position: relative;
	}

	.academyNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 45vh;
		left: 5vh;

		.academyNewN-box {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.academyNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 20vh;
		left: 5vh;

		.academyNewN-box2 {
			width: 100%;
			max-width: 900px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.academyNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 25vh;
		left: 5vh;

		.academyNewN-box3 {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}
}

//Portrait
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (orientation: portrait) {
	.academyNewN-mother {
		position: relative;
	}

	.academyNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.academyNewN-box {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.academyNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.academyNewN-box2 {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.academyNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.academyNewN-box3 {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}
}

//PC
@media (min-width: 900px) {
	.academyNewN-mother {
		position: relative;
	}

	.academyNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 45vh;
		left: 5vh;

		.academyNewN-box {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.academyNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 20vh;
		left: 5vh;

		.academyNewN-box2 {
			width: 100%;
			max-width: 900px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.academyNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 25vh;
		left: 5vh;

		.academyNewN-box3 {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.academyNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}
}

/////////////////

.academyNewN-paragraph .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.academyNewN-paragraph .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

////
/// 
.academyNewN-paragraph2 .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.academyNewN-paragraph2 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

////
.academyNewN-paragraph3 .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.academyNewN-paragraph3 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.fade-out-paragraph {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}