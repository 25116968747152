.buttons-container {
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7%;
  z-index: 100;
  width: auto;
  padding-left: 4.5px;
  padding-right: 4.5px;

  height: 10vw;
  max-height: 100px;
  border-radius: 45px;
  background: #151515;
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.5);
  @media screen and (max-height: 481px) {
    bottom: 3%;
    height: 11vh;
  }
  @media screen and (max-width: 481px) {
    bottom: 3%;
    height: 12vw;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.pictograms-list {
  list-style: none;
  width: inherit;
  height: inherit;
  max-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  .pictogram-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    max-width: 90px;
    max-height: 90px;
    height: 10vw;
    width: 10vw;
    background: #202020;
    border-top: 1px solid #353535;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
    color: grey !important;
    font-size: 40px;

    @media screen and (max-height: 481px) {
      height: 10vh;
      width: 10vh;
    }
    @media screen and (max-width: 481px) {
      height: 11vw;
      width: 11vw;
    }

    img {
      display: block;
      width: 60%;

      filter: invert(50%) sepia(16%) saturate(19%) hue-rotate(358deg) brightness(97%) contrast(86%);
      @media screen and (max-width: 481px) {
        width: 60%;
      }

      @media screen and (max-height: 481px) {
        width: 60%;
      }
    }

    &:nth-of-type(1) {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
    &:last-of-type {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .active {
    background: #151515;
    border-top: none;
    border-bottom: 1px solid #252525;
    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.8);
  }

  .active-text {
    //filter: invert(27%) sepia(90%) saturate(7434%) hue-rotate(341deg) brightness(89%) contrast(94%) !important;
    filter: brightness(0) invert(1) !important;
    text-shadow: 0 0 15px rgba(220, 10, 10, 0.5);
  }
}

.created-by {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 10%;
  width: 200px;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  p {
    color: white;
  }
  a {
    color: crimson;
    text-shadow: 0 0 15px rgba(244, 0, 0, 0.71);
    text-decoration: none;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

// .active {
//   background: #151515;
//   border-top: none;
//   border-bottom: 1px solid #252525;
//   box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.8);
// }

// .active-text {
//   color: crimson;
//   text-shadow: 0 0 15px rgba(220, 10, 10, 0.5);
// }
