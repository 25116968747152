.soundBtn {
  bottom: 6vh;
  left: 6vh;
  width: 6vh;
  height: 6vh;
  /* background-color: red; */
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sound-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sound-wave {
  width: auto;
  height: 23px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .barStop {
    display: block;
    width: 4px;
    margin-right: 4px;
    height: 3px;
    border-radius: 20px;
    background: #ffffff;
    transition: height 0.8s;
  }

  .bar {
    display: block;
    width: 4px;
    margin-right: 4px;
    height: 23px;
    border-radius: 20px;
    background: #ffffff;
    animation: sound 0ms -800ms linear infinite alternate;
    transition: height 0.8s;
  }
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }

  100% {
    opacity: 1;
    height: 23px;
  }
}

.bar:nth-child(1) {
  height: 1px;
  animation-duration: 474ms;
}

.bar:nth-child(2) {
  height: 5px;
  animation-duration: 433ms;
}

.bar:nth-child(3) {
  height: 9px;
  animation-duration: 407ms;
}

.bar:nth-child(4) {
  height: 13px;
  animation-duration: 458ms;
}

.bar:nth-child(5) {
  height: 15px;
  animation-duration: 400ms;
}

.bar:nth-child(6) {
  height: 16px;
  animation-duration: 427ms;
}

.bar:nth-child(7) {
  height: 17px;
  animation-duration: 441ms;
}

.bar:nth-child(8) {
  height: 18px;
  animation-duration: 419ms;
}

.bar:nth-child(9) {
  height: 20px;
  animation-duration: 487ms;
}

.bar:nth-child(10) {
  height: 23px;
  animation-duration: 442ms;
}

//   .sound-icon:hover {
//     .bar {
//       height: 5px;
//       animation-iteration-count: 1;
//       animation-fill-mode: backwards;
//     }
//   }

@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: portrait) {
  .soundBtn {
    position: fixed;
    top: 3vh;
    left: unset;
    right: 6vh;
    width: 6vh;
    height: 6vh;
    /* background-color: red; */
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: landscape) {
  .soundBtn {
    bottom: 6vh;
    left: 14vh;
    width: 6vh;
    height: 6vh;
    /* background-color: red; */
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-device-width: 819px) and (orientation: portrait) {
}

@media only screen and (min-device-width: 760px) and (max-device-width: 819px) and (orientation: portrait) {
}
