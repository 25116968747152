.textbox {
  opacity: 0;
  transition: all 0.1s linear;
  position: absolute;
  left: 0;
  top: 20vh;
  height: 45vh;
  right: 0;
  z-index: 100;
  pointer-events: none;
  @media screen and (max-height: 481px) {
    top: 11vw;
    height: 55vh;
  }

  @media screen and (max-width: 481px) {
    top: 22%;
    height: 55%;
  }
}

.textbox-bigger {
  height: 67%;
}

.showTextBox {
  opacity: 1;
}

.sideline-left {
  width: 1px;
  background-color: rgb(37, 54, 75);
  position: absolute;

  left: 3%;
  top: 48%;
  bottom: 48%;
  transition: all 0.5s linear;

  &:before {
    content: "";
    position: absolute;
    top: -7px;
    left: -6px;
    width: 5px;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid rgb(246, 59, 75);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: -6px;
    width: 5px;
    height: 0;
    border-bottom: 6px solid rgb(246, 59, 75);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  .center-stick {
    position: absolute;
    top: calc(50% - 7px);
    left: -2px;
    width: 5px;
    height: 14px;
    background-color: rgb(246, 59, 75);
  }
}

.sideline-right {
  width: 1px;
  background-color: rgb(37, 54, 75);
  position: absolute;
  right: 3%;
  top: 48%;
  bottom: 48%;
  transition: all 0.5s linear;

  &:before {
    content: "";
    position: absolute;
    top: -7px;
    left: -6px;
    width: 5px;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid rgb(246, 59, 75);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: -6px;
    width: 5px;
    height: 0;
    border-bottom: 6px solid rgb(246, 59, 75);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  .center-stick {
    position: absolute;
    top: calc(50% - 7px);
    left: -2px;
    width: 5px;
    height: 14px;
    background-color: rgb(246, 59, 75);
  }
}

.growSideLines {
  top: 2%;
  bottom: 2%;
}

.textbox-wrapper {
  opacity: 1;
  position: absolute;
  transition: all 0.3s linear;
  border: 1px solid rgb(37, 54, 75);
  left: 30%;
  right: 30%;
  top: 17%;
  bottom: 17%;
  transition: all 0.5s linear;

  .corner-left-top-top {
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: #f63b4c;
    top: -4px;
    left: -3px;
  }

  .corner-left-top-bottom {
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #f63b4c;
    top: -3px;
    left: -3px;
  }

  .corner-left-bottom-top {
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #f63b4c;
    bottom: -3px;
    left: -3px;
  }

  .corner-left-bottom-bottom {
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: #f63b4c;
    bottom: -4px;
    left: -3px;
  }

  .corner-right-top-top {
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: #f63b4c;
    top: -4px;
    right: -3px;
  }

  .corner-right-top-bottom {
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #f63b4c;
    top: -3px;
    right: -3px;
  }
  .corner-right-bottom-bottom {
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: #f63b4c;
    bottom: -4px;
    right: -3px;
  }
  .corner-right-bottom-top {
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #f63b4c;
    bottom: -3px;
    right: -3px;
  }
}

.textbox-wrapper-bigger {
  top: 7%;
  bottom: 7%;
}

.growTextBox {
  left: 17%;
  right: 17%;
  @media screen and (max-width: 481px) {
    left: 14%;
    right: 14%;
  }
}

.glass-background {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.left-line {
  width: 2px;

  position: absolute;
  left: 35%;
  top: 22%;
  bottom: 22%;
  background-color: #59b29e;
  transition: all 0.5s linear;

  &::before {
    content: "";
    width: 10px;
    height: 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 2px;
    background-color: #59b29e;
  }

  &::after {
    content: "";
    width: 10px;
    height: 3px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 2px;
    background-color: #59b29e;
  }
}

.left-line-bigger {
  top: 10%;
  bottom: 10%;
}

.animateLeftLine {
  left: 20%;
  @media screen and (max-width: 481px) {
    left: 17%;
  }
}

.right-line {
  width: 2px;
  top: 22%;
  bottom: 22%;
  position: absolute;
  right: 35%;

  background-color: #59b29e;
  transition: all 0.5s linear;
  &::before {
    content: "";
    width: 10px;
    height: 2px;
    display: block;
    position: absolute;
    top: 0;
    right: 2px;
    background-color: #59b29e;
  }

  &::after {
    content: "";
    width: 10px;
    height: 3px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 2px;
    background-color: #59b29e;
  }
}

.right-line-bigger {
  top: 10%;
  bottom: 10%;
}

.animateRightLine {
  right: 20%;
  @media screen and (max-width: 481px) {
    right: 17%;
  }
}

.top-line {
  height: 2px;
  position: absolute;
  right: 40%;
  left: 40%;
  top: 2%;
  background-color: #59b29e;
  transition: all 0.5s linear;
  &::before {
    content: "";
    width: 2px;
    height: 10px;
    display: block;
    position: absolute;
    top: 2px;
    left: 0;
    background-color: #59b29e;
  }

  &::after {
    content: "";
    width: 2px;
    height: 10px;
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    background-color: #59b29e;
  }
}

.bottom-line {
  height: 2px;
  position: absolute;
  right: 40%;
  left: 40%;
  bottom: 2%;
  background-color: #59b29e;
  transition: all 0.5s linear;
  &::before {
    content: "";
    width: 2px;
    height: 10px;
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    background-color: #59b29e;
  }

  &::after {
    content: "";
    width: 2px;
    height: 10px;
    display: block;
    position: absolute;
    bottom: 2px;
    right: 0;
    background-color: #59b29e;
  }
}

.animateHorisontalLine {
  left: 30%;
  right: 30%;
  @media screen and (max-width: 481px) {
    left: 25%;
    right: 25%;
  }
}

.dots-wrapper-top {
  position: absolute;
  top: 7%;
  left: 25%;
  right: 25%;
  display: flex;
  justify-content: center;
  transform: scaleY(-1);
  @media screen and (max-width: 481px) {
    left: 30%;
    right: 30%;
    overflow: hidden;
  }
}

.dots-wrapper-top-bigger {
  top: 4%;
}

.dots-wrapper-bottom {
  position: absolute;
  left: 25%;
  right: 25vw;
  bottom: 7%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 481px) {
    left: 30%;
    right: 30%;
    overflow: hidden;
  }
}

.dots-wrapper-bottom-bigger {
  bottom: 4%;
}

.dot {
  background: rgba(89, 178, 158, 0.3);
  width: 1px;
  height: 10px;
  position: relative;
  display: inline-block;
  margin: 0 3px;

  white-space: nowrap;
  &::before {
    content: "";
    width: inherit;
    height: 2px;
    left: 0;
    background: #00ffc3;
    position: absolute;
  }
}

.center-line {
  height: 1px;
  background-color: rgba(37, 54, 75, 0.5);
  position: absolute;
  top: 50%;
  left: 45%;
  right: 45%;
  transition: all 0.5s linear;
}

.growCenterLine {
  left: 0;
  right: 0;
}
.animateLeft {
  animation-name: animateLeft;
  animation-duration: 500ms, 500ms;
  animation-delay: 0s, 10000ms;
  animation-timing-function: linear, linear;
  animation-fill-mode: forwards, forwards;
  animation-iteration-count: 1, 1;
  animation-play-state: paused;
}

.animateRight {
  animation-name: animateRight;
  animation-duration: 500ms, 500ms;
  animation-delay: 0s, 10000ms;
  animation-timing-function: linear, linear;
  animation-fill-mode: forwards, forwards;
  animation-iteration-count: 1, 1;
  animation-play-state: paused;
}

.growBox {
  animation-name: growBoxLeft, growBoxRight;
  animation-duration: 500ms, 500ms, 500ms, 500ms;
  animation-delay: 0ms, 0ms, 10000ms, 10000ms; /* add this */
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1, 1, 1, 1;
  animation-play-state: paused;
}

.growCenterLine {
  // animation-name: growCenterLineLeft, growCenterLineRight;
  // animation-duration: 500ms, 500ms, 500ms, 500ms;
  // animation-delay: 0ms, 0ms, 10000ms, 10000ms; /* add this */
  // animation-timing-function: linear;
  // animation-fill-mode: forwards;
  // animation-iteration-count: 1, 1, 1, 1;
  // animation-play-state: paused;
}

@keyframes animateLeftHorizontalLine {
  100% {
    left: 20vw;
  }
}

@keyframes animateRightHorizontalLine {
  100% {
    right: 20vw;
  }
}

@keyframes animateLeftHorizontalLineBack {
  100% {
    left: 40vw;
  }
}

@keyframes animateRightHorizontalLineBack {
  100% {
    right: 40vw;
  }
}

@keyframes animateLeft {
  100% {
    left: 15vw;
  }
}

@keyframes animateLeftBack {
  100% {
    left: 35vw;
  }
}

@keyframes animateRight {
  100% {
    right: 15vw;
  }
}

@keyframes animateRightBack {
  100% {
    right: 35vw;
  }
}

@keyframes showText {
  100% {
    opacity: 1;
  }
}

@keyframes hideText {
  100% {
    opacity: 0;
  }
}

@keyframes growBoxLeft {
  100% {
    left: 12vw;
  }
}
@keyframes growBoxRight {
  100% {
    right: 12vw;
  }
}

@keyframes growBoxLeftBack {
  100% {
    left: 30vw;
  }
}

@keyframes growBoxRightBack {
  100% {
    right: 30vw;
  }
}

@keyframes growSideLineTop {
  100% {
    top: 25vh;
  }
}

@keyframes growSideLineBottom {
  100% {
    bottom: calc(100vh - 65vh);
  }
}

@keyframes growSideLineTopBack {
  100% {
    top: 43vh;
  }
}

@keyframes growSideLineBottomBack {
  100% {
    bottom: 53vh;
  }
}

@keyframes growCenterLineLeft {
  100% {
    left: 0;
  }
}

@keyframes growCenterLineRight {
  100% {
    right: 0;
  }
}

@keyframes growCenterLineLeftBack {
  100% {
    left: 45vw;
  }
}

@keyframes growCenterLineRightBack {
  100% {
    right: 45vw;
  }
}

@keyframes showTextbox {
  100% {
    opacity: 1;
  }
}

.displayed-text {
  text-align: center;
  position: absolute;
  left: 21%;
  right: 21%;
  top: 50%;
  transform: translateY(-51%);
  color: #d3e7d6;
  text-shadow: 2px 2px 4px #000;
  font-size: 24px;
  opacity: 0;
  transition: all 0.5s linear;
  @media screen and (max-height: 481px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    left: 19%;
    right: 19%;
  }

  .academy-certificates {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: -25px;
    .academy-certificate {
      width: 13%;
      display: flex;
      align-items: center;
      img {
        display: block;
        width: 100%;
      }
    }
  }
}

.showText {
  opacity: 1;
}

.side-circles {
  position: absolute;
  top: calc(50% - 118px / 2);
  @media screen and (max-width: 481px) {
    top: calc(50% - 96px / 2);
  }
}

.side-circles-left {
  left: 5%;
  @media screen and (max-width: 481px) {
    left: 7%;
  }
}

.side-circles-right {
  right: 5%;
  @media screen and (max-width: 481px) {
    right: 7%;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-bottom: 8px;
  border: 1px solid rgb(89, 178, 158);
  visibility: hidden;
  @media screen and (max-width: 481px) {
    width: 8px;
    height: 8px;
  }
}
