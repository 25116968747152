///
.solutions-paragraph .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.solutions-paragraph .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

/// 
.solutions-paragraph2 .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.solutions-paragraph2 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

////
.solutions-paragraph3 .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.solutions-paragraph3 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.fade-out-paragraph {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}
