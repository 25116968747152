#w {
    height: 100%;
    width: 100%;
    bottom: 0;
    margin: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    background: rgba(0, 10, 20, 1);
    background-size: cover;
    image-rendering: pixelated;
    font-family: sans-serif;
    z-index: -1;
  }
  
#space {
    width: 100%;
    height: 100%; 
}

// #warp {
//     position: absolute;
//     z-index: 9;
//     bottom: -10vh;
//     left: 0;
//     right: 0;
//     margin: 20px auto;
//     color: rgba(209, 255, 255, 1);
//     border: 2px solid;
//     padding: 1em;
//     width: 10em;
//     text-align: center;
//     font-weight: 700;
//     font-size: 1.2em;
//     display: inline-block;
//     text-decoration: none;
//     background: rgba(0, 0, 0, 0.8)
// }
