
.newRND-texts {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    padding: 10px;
    top: 400px;
    pointer-events: none;
  
    .newRND-box {
      width: 100%;
      max-width: 400px;
      // adjust height as needed
      height: auto;
      transition: height 0.1s linear;
      pointer-events: none;
    }
  
    .newRND-paragraph {
      font-family: "Segoe_UI_Semibold";
      margin-bottom: 10px;
      font-size: 18px;
      pointer-events: none;
    }
  }
  
  @media (min-width: 768px) {
  
    /* styles for larger screens */
    .newRND-texts {
      /* adjust positioning as needed */
      position: absolute;
      top: -20%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
  
      .newRND-box {
        width: 100%;
        max-width: 400px;
        // adjust height as needed
        height: auto;
        transition: height 0.1s linear;
        pointer-events: none;
      }
  
      .newRND-paragraph {
        font-family: "Segoe_UI_Semibold";
        margin-bottom: 10px;
        font-size: 17px;
        pointer-events: none;
      }
    }
  }

  
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 
/*
.rndNewN-texts {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;

	.rndNewN-box {
		width: 100%;
		max-width: 400px;
		// adjust height as needed
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.rndNewN-paragraph {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
	}
}
@media (min-width: 768px) {

	.rndNewN-texts {
		position: absolute;
		top: 65%;
		bottom: 0;
		left: -35%;
		right: 0;
		pointer-events: none;

		.rndNewN-box {
			width: 100%;
			max-width: 46%;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 34px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}
.rndNewN-texts2 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;

	.rndNewN-box2 {
		width: 100%;
		max-width: 400px;
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.rndNewN-paragraph2 {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		text-align: right;
		pointer-events: none;
		white-space: nowrap;
	}
}
@media (min-width: 768px) {

	.rndNewN-texts2 {
		position: absolute;
		top: -56%;
		bottom: 0;
		right: 5vh;
		pointer-events: none;


		.rndNewN-box2 {
			width: auto;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			text-align: right;
			font-size: 34px;
			pointer-events: none;
			white-space: nowrap;
			float: right;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}
.rndNewN-texts3 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;

	.rndNewN-box3 {
		width: 100%;
		max-width: 400px;
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.rndNewN-paragraph3 {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
		text-align: right;
	}
}
@media (min-width: 768px) {

	.rndNewN-texts3 {
		position: absolute;
		top: 70vh;
		pointer-events: none;
		right: 5vh;

		.rndNewN-box3 {
			width: auto;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			text-align: right;
			white-space: nowrap;
			float: right;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}*/

//Landscape
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (orientation: landscape) {
	.rndNewN-mother {
		position: relative;
	}

	.rndNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 75vh;
		left: 5vh;

		.rndNewN-box {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.rndNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 20vh;
		right: 5vh;

		.rndNewN-box2 {
			width: 100%;
			max-width: 900px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
			text-align: right;
			float: right;
		}
	}

	.rndNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 70vh;
		right: 5vh;

		.rndNewN-box3 {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
			text-align: right;
			float: right;
		}
	}
}

//Portrait
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (orientation: portrait) {
	.rndNewN-mother {
		position: relative;
	}

	.rndNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.rndNewN-box {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.rndNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.rndNewN-box2 {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.rndNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.rndNewN-box3 {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}
}

//PC
@media (min-width: 900px) {
	.rndNewN-mother {
		position: relative;
	}

	.rndNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 75vh;
		left: 5vh;

		.rndNewN-box {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.rndNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 20vh;
		right: 5vh;

		.rndNewN-box2 {
			width: 100%;
			max-width: 900px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
			text-align: right;
			float: right;
		}
	}

	.rndNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 70vh;
		right: 5vh;

		.rndNewN-box3 {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.rndNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
			text-align: right;
			float: right;
		}
	}
}


/////////////////

.rndNewN-paragraph .letter {
	display: inline-block;
	opacity: 0; 
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
	opacity 1s ease-out;
  }
  
  .rndNewN-paragraph .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes jump-in {
	from {
	  transform: translate3d(0px, 50px, -100px);
	  opacity: 0;
	}
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  ////
  /// 
.rndNewN-paragraph2 .letter {
	display: inline-block;
	opacity: 0; 
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
	opacity 1s ease-out;
  }
  
  .rndNewN-paragraph2 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes jump-in {
	from {
	  transform: translate3d(0px, 50px, -100px);
	  opacity: 0;
	}
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  ////
.rndNewN-paragraph3 .letter {
	display: inline-block;
	opacity: 0; 
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
	opacity 1s ease-out;
  }
  
  .rndNewN-paragraph3 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes jump-in {
	from {
	  transform: translate3d(0px, 50px, -100px);
	  opacity: 0;
	}
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }

  .fade-out-paragraph {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
  }

  ///////////////////////////////////////////// 
  .rndVideoDiv {
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
  }
  
  .rndVideoDivdesktop {
	left: 51%;
  }
  
  
  @media only screen and (max-width: 768px) {
	.rndVideoDiv {
	  top: 50%;
	  transform: translate(-50%, -50%);
	}
	
	.rndVideoDivdesktop {
		left: 57%;
	}
  }

  .hideMe{
	display: none;
  }
  