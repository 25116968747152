.popup {
  width: 100%;
  height: 100%;

  background-color: grey;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  button {
    width: 140px;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
    color: black;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.popup-message {
  p {
    font-family: "Segoe UI Light";
    margin: 0;
    color: white;
    font-size: 18px;
    width: 100%;
    text-align: center;
    opacity: 1;
    margin-bottom: 10px;
  }

  .server-error {
    color: rgb(217, 93, 31);
  }
}
