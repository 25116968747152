////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 


//Landscape
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (orientation: landscape) {
	.businessNewN-mother {
		position: relative;
	}

	.businessNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		bottom: 15vh;
		left: 20px;

		.businessNewN-box {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 18px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.businessNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 50vh;
		left: 93vh;

		.businessNewN-box2 {
			width: 100%;
			max-width: 600px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 18px;
			text-align: right;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.businessNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		bottom: 15vh;
		left: 10vh;

		.businessNewN-box3 {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 18px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}
}

//Portrait
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (orientation: portrait) {
	.businessNewN-mother {
		position: relative;
	}

	.businessNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.businessNewN-box {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.businessNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.businessNewN-box2 {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.businessNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		padding: 10px;
		top: 80vh;
		pointer-events: none;
		z-index: 20;

		.businessNewN-box3 {
			width: 100%;
			max-width: 400px;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}
}


//PC
@media  (min-width: 900px)  {
	.businessNewN-mother {
		position: relative;
	}

	.businessNewN-texts {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 65vh;
		left: 5vh;

		.businessNewN-box {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.businessNewN-texts2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 50vh;
		right: 5vh;

		.businessNewN-box2 {
			width: 100%;
			max-width: 900px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			text-align: right;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}

	.businessNewN-texts3 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		color: white;
		pointer-events: none;
		z-index: 50;
		top: 70vh;
		left: 5vh;

		.businessNewN-box3 {
			width: 100%;
			max-width: 400px;
			height: 20%;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.businessNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
			color: white;
			z-index: 50;
		}
	}
}
//PC
// @media (min-width: 900px) {

// 	.businessNewN-mother {
// 		position: relative;
// 	}

// 	.businessNewN-texts {
// 		position: absolute;
// 		top: 300px;
// 		bottom: 0;
// 		left: 2%;
// 		right: 0;
// 		pointer-events: none;
// 		z-index: 20;

// 		.businessNewN-box {
// 			width: 100%;
// 			max-width: 46%;
// 			height: auto;
// 			transition: height 0.1s linear;
// 			pointer-events: none;
// 		}

// 		.businessNewN-paragraph {
// 			font-family: "Segoe_UI_Semibold";
// 			margin-bottom: 10px;
// 			font-size: 34px;
// 			pointer-events: none;
// 			// white-space: nowrap;
// 			white-space: nowrap;
// 			color: white;
// 		}

// 	}

// 	.businessNewN-texts2 {
// 		position: absolute;
// 		top: 13%;
// 		bottom: 0;
// 		left: 53%;
// 		right: 0;
// 		pointer-events: none;
// 		z-index: 20;


// 		.businessNewN-box2 {
// 			width: 100%;
// 			max-width: 90%;
// 			height: auto;
// 			transition: height 0.1s linear;
// 			pointer-events: none;
// 		}

// 		.businessNewN-paragraph2 {
// 			font-family: "Segoe_UI_Semibold";
// 			margin-bottom: 10px;
// 			font-size: 34px;
// 			pointer-events: none;
// 			text-align: right;
// 			white-space: nowrap;
// 			color: white;
// 		}

// 	}

// 	.businessNewN-texts3 {
// 		/* adjust positioning as needed */
// 		position: absolute;
// 		top: 55%;
// 		bottom: 0;
// 		left: 2%;
// 		right: 0;
// 		pointer-events: none;
// 		z-index: 20;

// 		.businessNewN-box3 {
// 			width: 100%;
// 			max-width: 55%;
// 			// adjust height as needed
// 			height: auto;
// 			transition: height 0.1s linear;
// 			pointer-events: none;
// 		}

// 		.businessNewN-paragraph3 {
// 			font-family: "Segoe_UI_Semibold";
// 			margin-bottom: 10px;
// 			font-size: 37px;
// 			pointer-events: none;
// 			white-space: nowrap;
// 			color: white;
// 		}

// 	}

// 	.scale {
// 		animation: scale linear 0.5s forwards;
// 	}

// 	.unscale {
// 		animation: unscale linear 0.5s forwards;
// 	}

// 	@keyframes scale {
// 		0% {
// 			width: 100px;
// 		}

// 		100% {
// 			width: 600px;
// 		}
// 	}

// 	@keyframes unscale {
// 		0% {
// 			width: 600px;
// 		}

// 		100% {
// 			width: 100px;
// 		}
// 	}
// }

/////////////////

.businessNewN-paragraph .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.businessNewN-paragraph .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

////
/// 
.businessNewN-paragraph2 .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.businessNewN-paragraph2 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

////
.businessNewN-paragraph3 .letter {
	display: inline-block;
	opacity: 0;
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
		opacity 1s ease-out;
}

.businessNewN-paragraph3 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes jump-in {
	from {
		transform: translate3d(0px, 50px, -100px);
		opacity: 0;
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}
