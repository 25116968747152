.pipe-container {
  position: absolute;
  z-index: 300;
  top: 20vh;
  right: 14vh;
  height: 60vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pipe {
  position: relative;
  width: 2px;
  height: 100%;
  background-color: #ffffff00;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.fill {
  width: 6px;
  background-color: #ffffff00;
  transition: height 1s ease-in-out;
  left: -0.2vh;
  bottom: 100%;
  top: auto;
  transform: translateY(100%);
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pulsating-circle {
  position: absolute;
  transition: top 1s ease-in-out;
  width: 20px;
  height: 20px;
  top: 0vh;
  left: -0.8vh;
  background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes pulsate {
  0% {
    transform: scale(1.2);
    opacity: 1;
  }

  50% {
    transform: scale(1.7);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.pipe-input {
  writing-mode: vertical-rl;
  appearance: none;
  width: 30px;
  height: 150px;
  background: transparent;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 0;
  margin: 0;
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-start {
  position: absolute;
  top: -0.7vh;
  // left: -3vh;
  transform: translateX(-150%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    color: white;
    font-family: "Segoe UI Semibold";
    margin-bottom: 10px;
    font-size: 30px;
    pointer-events: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.input-end {
  position: absolute;
  bottom: -3.3vh;
  // left: -2vh;
  transform: translateX(50%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  p {
    color: white;
    font-family: "Segoe UI Semibold";
    margin-bottom: 10px;
    font-size: 30px;
    pointer-events: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.line-zero {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  // background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-first {
  // position: absolute;
  // width: 6vh;
  // height: 0.2px;
  // background-color: #ffffff;
  // top: 35%;
  // transform: translateX(-50%);
  // color: white;
  // font-size: 100px;
  // -webkit-user-select: none;
  // /* Safari */
  // -moz-user-select: none;
  // /* Firefox */
  // -ms-user-select: none;
  // /* IE10+/Edge */
  // user-select: none;
  // /* Standard */
  // pointer-events: none;

  position: absolute;
  width: 20px;
  height: 20px;
  top: 33%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  // background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-second {
  // position: absolute;
  // width: 6vh;
  // height: 0.2px;
  // background-color: #ffffff;
  // top: 68%;
  // transform: translateX(-50%);
  // color: white;
  // font-size: 100px;
  // -webkit-user-select: none;
  // /* Safari */
  // -moz-user-select: none;
  // /* Firefox */
  // -ms-user-select: none;
  // /* IE10+/Edge */
  // user-select: none;
  // /* Standard */
  // pointer-events: none;

  position: absolute;
  width: 20px;
  height: 20px;
  top: 66%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  // background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-third {
  // position: absolute;
  // width: 6vh;
  // height: 0.2px;
  // background-color: #ffffff;
  // top: 68%;
  // transform: translateX(-50%);
  // color: white;
  // font-size: 100px;
  // -webkit-user-select: none;
  // /* Safari */
  // -moz-user-select: none;
  // /* Firefox */
  // -ms-user-select: none;
  // /* IE10+/Edge */
  // user-select: none;
  // /* Standard */
  // pointer-events: none;

  position: absolute;
  width: 20px;
  height: 20px;
  top: 100%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  // background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-fourth {
  // position: absolute;
  // width: 6vh;
  // height: 0.2px;
  // background-color: #ffffff;
  // top: 68%;
  // transform: translateX(-50%);
  // color: white;
  // font-size: 100px;
  // -webkit-user-select: none;
  // /* Safari */
  // -moz-user-select: none;
  // /* Firefox */
  // -ms-user-select: none;
  // /* IE10+/Edge */
  // user-select: none;
  // /* Standard */
  // pointer-events: none;

  // position: absolute;
  // width: 20px;
  // height: 20px;
  // top: 100%;
  // left: -0.8vh;
  // background: radial-gradient(
  //   circle at center,
  //   rgba(255, 0, 255, 0) 0%,
  //   rgba(255, 0, 255, 0) 64.25%,
  //   rgb(255, 255, 255) 64.25%
  // );
  // // background-color: #ffffff;
  // border-radius: 50%;
  // animation: pulsate 1s ease-out;
  // animation-iteration-count: infinite;
  // opacity: 1;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  // pointer-events: none;
}

.pipe-input {
  writing-mode: vertical-rl;
  appearance: none;
  width: 30px;
  height: 150px;
  background: transparent;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 0;
  margin: 0;
  display: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

// .input-start {
//   position: absolute;
//   top: -0.7vh;
//   // left: -3vh;
//   transform: translateX(-150%);
//   -webkit-user-select: none;
//   /* Safari */
//   -moz-user-select: none;
//   /* Firefox */
//   -ms-user-select: none;
//   /* IE10+/Edge */
//   user-select: none;
//   /* Standard */
// }

// .input-start p {
//   color: white;
//   font-family: "Segoe_UI_Semibold";
//   margin-bottom: 10px;
//   font-size: 30px;
//   pointer-events: none;
//   white-space: nowrap;
//   -webkit-user-select: none;
//   /* Safari */
//   -moz-user-select: none;
//   /* Firefox */
//   -ms-user-select: none;
//   /* IE10+/Edge */
//   user-select: none;
//   /* Standard */
// }

// .input-end {
//   position: absolute;
//   bottom: -3.3vh;
//   // left: -2vh;
//   transform: translateX(50%);
//   -webkit-user-select: none;
//   /* Safari */
//   -moz-user-select: none;
//   /* Firefox */
//   -ms-user-select: none;
//   /* IE10+/Edge */
//   user-select: none;
//   /* Standard */
// }

// .input-end p {
//   color: white;
//   font-family: "Segoe_UI_Semibold";
//   margin-bottom: 10px;
//   font-size: 30px;
//   pointer-events: none;
//   white-space: nowrap;
//   -webkit-user-select: none;
//   /* Safari */
//   -moz-user-select: none;
//   /* Firefox */
//   -ms-user-select: none;
//   /* IE10+/Edge */
//   user-select: none;
//   /* Standard */
// }

// PIXELS PAGE
.line-zero-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-first-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 16.66%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

.line-second-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 33.33%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-fourth-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 66.65%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

.line-fifth-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 83.31%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

.line-sixth-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 100%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

// THIS IS THE LAST ONE
.line-third-Pixels {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 49.99%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

// SOLUTIONS PAGE
.line-zero-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-first-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12.5%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-second-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 25%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-fourth-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 37.5%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

.line-fifth-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-sixth-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 62.5%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-seventh-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 75%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

.line-eighth-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 87.5%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

// THIS IS THE LAST ONE
.line-third-Solution {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 100%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

//business-consulting page

.line-zero-bc {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-first-bc {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 25%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-second-bc {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-third-bc {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 75%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

.line-fourth-bc {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 100%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

// ACADEMY PAGE
.line-zero-academy {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //pointer-events: none;
}

.line-first-academy {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

.line-second-academy {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 66%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //  pointer-events: none;
}

// THIS IS THE LAST ONE
.line-third-academy {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 100%;
  left: -0.8vh;
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 255, 0) 0%,
    rgba(255, 0, 255, 0) 64.25%,
    rgb(255, 255, 255) 64.25%
  );
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // pointer-events: none;
}

@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: portrait) {
  .pipe-container {
    position: absolute;
    z-index: 300;
    top: 15vh;
    right: 5vh;
    height: 60vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .pipe {
    position: relative;
    width: 2px;
    height: 100%;
    background-color: #ffffff00;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .fill {
    width: 6px;
    background-color: #ffffff00;
    transition: height 1s ease-in-out;
    left: -0.2vh;
    bottom: 100%;
    top: auto;
    transform: translateY(100%);
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .pulsating-circle {
    position: absolute;
    transition: top 1s ease-in-out;
    width: 20px;
    height: 20px;
    top: 0vh;
    left: calc(-0.8vh + 10px);
    background-color: #ffffff;
    background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @keyframes pulsate {
    0% {
      transform: scale(1.2);
      opacity: 1;
    }

    50% {
      transform: scale(1.7);
      opacity: 1;
    }

    100% {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  .pipe-input {
    writing-mode: vertical-rl;
    appearance: none;
    width: 30px;
    height: 150px;
    background: transparent;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding: 0;
    margin: 0;
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .input-start {
    position: absolute;
    top: 0vh;
    left: 1vh;
    transform: translateX(-150%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .input-start p {
    color: white;
    font-family: "Segoe UI Semibold";
    font-size: 20px;
    pointer-events: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .input-end {
    position: absolute;
    bottom: -3.5vh;
    transform: translateX(-150%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .input-end p {
    color: white;
    font-family: "Segoe UI Semibold";
    font-size: 20px;
    pointer-events: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .line-zero {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-first {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 33%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-second {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 66%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-third {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  //business-consulting page

  .line-zero-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-first-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-second-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-third-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-fourth-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // PIXELS PAGE
  .line-zero-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-first-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 16.66%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-second-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 33.33%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-fourth-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 66.65%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-fifth-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 83.31%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-sixth-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  // THIS IS THE LAST ONE
  .line-third-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 49.99%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // SOLUTIONS PAGE
  .line-zero-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-first-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 14.3%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-second-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 28.6%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-fourth-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 42.9%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-fifth-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 57.2%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-sixth-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 71.5%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-seventh-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 85.8%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-eighth-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 85.8%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // THIS IS THE LAST ONE
  .line-third-Solution {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // ACADEMY PAGE
  .line-zero-academy {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-first-academy {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 50%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // THIS IS THE LAST ONE
  .line-third-academy {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }
}

@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: landscape) {
  .pipe-container {
    position: absolute;
    z-index: 300;
    top: 12vh;
    right: 15vh;
    height: 60vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .pipe {
    position: relative;
    width: 2px;
    height: 100%;
    background-color: #ffffff00;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .fill {
    width: 6px;
    background-color: #ffffff00;
    transition: height 2s ease-in-out;
    left: -0.2vh;
    bottom: 100%;
    top: auto;
    transform: translateY(100%);
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .pulsating-circle {
    position: absolute;
    transition: top 1s ease-in-out;
    width: 15px;
    height: 15px;
    top: 0vh;
    left: calc(-0.8vh + 10px);
    background-color: #ffffff;
    background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @keyframes pulsate {
    0% {
      transform: scale(1.2);
      opacity: 1;
    }

    50% {
      transform: scale(1.7);
      opacity: 1;
    }

    100% {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  .pipe-input {
    writing-mode: vertical-rl;
    appearance: none;
    width: 30px;
    height: 150px;
    background: transparent;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding: 0;
    margin: 0;
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .input-start {
    position: absolute;
    top: 0vh;
    left: 2vh;
    transform: translateX(-150%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    p {
      color: white;
      font-family: "Segoe UI Semibold";
      font-size: 20px;
      pointer-events: none;
      white-space: nowrap;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .input-end {
    position: absolute;
    bottom: -8.5vh;
    left: 2vh;
    transform: translateX(-150%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    p {
      color: white;
      font-family: "Segoe UI Semibold";
      font-size: 20px;
      pointer-events: none;
      white-space: nowrap;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .line-zero {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-first {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 33%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-second {
    position: absolute;
    width: 20px;
    height: 20px;
    // top: 66%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-third {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  //business-consulting page

  .line-zero-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-first-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-second-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-third-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //   pointer-events: none;
  }

  .line-fourth-bc {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  // PIXELS PAGE
  .line-zero-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-first-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 16.66%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-second-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 33.33%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-fourth-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 66.65%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-fifth-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 83.31%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  .line-sixth-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // THIS IS THE LAST ONE
  .line-third-Pixels {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 49.99%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  // SOLUTIONS PAGE
  .line-zero-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-first-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //  top: 14.3%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-second-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 28.6%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-fourth-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 42.9%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-fifth-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 57.2%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-sixth-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 71.5%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-seventh-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 85.8%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  .line-eighth-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //   top: 85.8%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // THIS IS THE LAST ONE
  .line-third-Solution {
    position: absolute;
    width: 15px;
    height: 15px;
    //    top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // pointer-events: none;
  }

  // ACADEMY PAGE
  .line-zero-academy {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 0%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
  }

  .line-first-academy {
    position: absolute;
    width: 20px;
    height: 20px;
    //   top: 50%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }

  // THIS IS THE LAST ONE
  .line-third-academy {
    position: absolute;
    width: 20px;
    height: 20px;
    //  top: 100%;
    left: calc(-0.8vh + 10px);
    background: radial-gradient(
      circle at center,
      rgba(255, 0, 255, 0) 0%,
      rgba(255, 0, 255, 0) 64.25%,
      rgb(255, 255, 255) 64.25%
    );
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //  pointer-events: none;
  }
}

@media only screen and (min-device-width: 819px) and (orientation: portrait) {
}

@media only screen and (min-device-width: 760px) and (max-device-width: 819px) and (orientation: portrait) {
}

/*
@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: portrait) {
	.pipe-container {
		position: absolute;
		z-index: 300;
		top: 30vh;
		right: 4vh;
		height: 40vh;
		width: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.pipe {
		position: relative;
		width: 1px;
		height: 100%;
		background-color: #ffffff;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.fill {
		width: 3px;
		background-color: #ffffff;
		transition: height 2s ease-in-out;
		left: -0.2vh;
		bottom: 100%;
		top: auto;
		transform: translateY(100%);
		position: absolute;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.pulsating-circle {
		position: absolute;
		transition: top 2s ease-in-out;
		width: 25px;
		height: 25px;
		top: 0vh;
		left: -1.5vh;
		background-color: #ffffff;
		border-radius: 50%;
		animation: pulsate 1s ease-out;
		animation-iteration-count: infinite;
		opacity: 1;
	}

	.input-start {
		position: absolute;
		top: -5vh;
		left: 50%;
		transform: translateX(-50%);
	}

	.input-start p {
		color: white;
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 20px;
		pointer-events: none;
		white-space: nowrap;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.input-end {
		position: absolute;
		bottom: -10vh;
		left: 50%;
		transform: translateX(-50%);
	}

	.input-end p {
		color: white;
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 20px;
		pointer-events: none;
		white-space: nowrap;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.line-first {
		position: absolute;
		width: 6vh;
		height: 0.2px;
		background-color: #ffffff;
		top: 37%;
		transform: translateX(-50%);
		color: white;
		font-size: 100px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		pointer-events: none;
	}

	.line-second {
		position: absolute;
		width: 6vh;
		height: 0.2px;
		background-color: #ffffff;
		top: 70%;
		transform: translateX(-50%);
		color: white;
		font-size: 100px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		pointer-events: none;
	}
}*/
/*
@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: landscape) {
	.pipe-container {
		position: absolute;
		z-index: 300;
		top: 17vh;
		right: 15vh;
		height: 45vh;
		width: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.pipe {
		position: relative;
		width: 2px;
		height: 100%;
		background-color: #ffffff;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.fill {
		width: 6px;
		background-color: #ffffff;
		transition: height 2s ease-in-out;
		left: -0.2vh;
		bottom: 100%;
		top: auto;
		transform: translateY(100%);
		position: absolute;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.pulsating-circle {
		position: absolute;
		transition: top 2s ease-in-out;
		width: 25px;
		height: 25px;
		top: 0vh;
		left: -3vh;
		background-color: #ffffff;
		border-radius: 50%;
		animation: pulsate 1s ease-out;
		animation-iteration-count: infinite;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		opacity: 1;
	}

	.input-start {
		position: absolute;
		top: -7vh;
		left: 50%;
		transform: translateX(-50%);
	}

	.input-start p {
		color: white;
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 20px;
		pointer-events: none;
		white-space: nowrap;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.input-end {
		position: absolute;
		bottom: -13vh;
		left: 50%;
		transform: translateX(-50%);
	}

	.input-end p {
		color: white;
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 20px;
		pointer-events: none;
		white-space: nowrap;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.line-first {
		position: absolute;
		width: 12vh;
		height: 0.2px;
		background-color: #ffffff;
		top: 39%;
		transform: translateX(-50%);
		color: white;
		font-size: 100px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		pointer-events: none;
	}

	.line-second {
		position: absolute;
		width: 12vh;
		height: 0.2px;
		background-color: #ffffff;
		top: 72%;
		transform: translateX(-50%);
		color: white;
		font-size: 100px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		pointer-events: none;
	}

}*/

.close-button.close-bottom {
  position: absolute;
  z-index: -11111;
}
