#contact {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
}

.new-contact {
  position: fixed;

  right: -450px;

  top: 50%;
  transform: translateY(-50%);
  width: 450px;
  height: auto;

  padding: 30px;

  background: rgba(37, 37, 37, 0.4);
  z-index: 1000;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(10px);

  transition: all 0.3s linear;
  @media screen and (max-height: 480px) {
    top: 0;
    height: 100vh;
    transform: none;
    padding: 0 7px;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 481px) {
    padding: 10px;
    width: 100%;
    right: unset;
    left: -100%;
    transform: none;
    top: 9vw;
    height: 80vh;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    pointer-events: none;
  }

  .contact-block {
    width: 350px;

    color: #fff;
    pointer-events: none;

    @media screen and (max-width: 481px) {
      width: 100%;
    }

    .contact-title {
      color: white;
      font-size: 46px;
      text-transform: uppercase;
      font-family: "Segoe UI Bold";
      font-style: normal;
      font-weight: normal;

      @media screen and (max-height: 481px) {
        font-size: 30px;
        margin-bottom: 10px;
      }
    }

    input,
    textarea,
    button,
    a {
      pointer-events: all;
    }
  }

  .description {
    font-size: 16px;
    width: 350px;
    margin-bottom: 20px;
    @media screen and (max-height: 480px) {
      display: none;
    }

    @media screen and (max-width: 481px) {
      width: 90%;
      font-size: 14px;
    }
  }

  .contact-form {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    ::placeholder {
      font-family: "Segoe UI Light";
      color: white;
    }

    @media screen and (max-width: 481px) {
      width: 90%;
    }

    .input-wrapper {
      width: 100%;
      margin-bottom: 12px;
      padding-bottom: 12px;
      position: relative;
      @media screen and (max-height: 481px) {
        margin-bottom: 6px;
      }
    }

    input,
    textarea {
      outline: none;
      display: block;
      box-sizing: border-box;
      width: 100%;
      border: 2px solid white;
      padding: 12px 22px;
      //   border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.08);
      color: #fff;
      font-family: "Segoe UI Light";
      font-size: 13px;
      @media screen and (max-height: 480px) {
        padding: 4px 7px;
      }
      @media screen and (max-width: 481px) {
        padding: 6px 11px;
        font-size: 10px;
      }
    }

    .validation-error {
      color: rgb(217, 93, 31);
      font-size: 12px;
      margin: 0;
      position: absolute;
      bottom: -5px;
      left: 20px;
      font-weight: 600;
    }

    .contact-button {
      width: 140px;
      padding: 10px 20px;
      border: none;
      outline: none;
      border-radius: 30px;
      font-size: 12px;
      text-transform: capitalize;
      cursor: pointer;
      color: black;
      font-weight: 600;
      text-transform: capitalize;
      background-color: white;
      @media screen and (max-width: 481px) {
        width: 120px;
        font-size: 10px;
      }
    }
  }

  .contact-block-data {
    .contact-block-data-item {
      text-decoration: none;

      color: white;
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;
      font-family: "Segoe UI Light";
      p {
        display: inline-block;
        margin-left: 15px;
      }

      img {
        display: inline-block;
        width: 20px;
        height: auto;
        @media screen and (max-width: 481px) {
          width: 14px;
        }
      }
    }
    @media screen and (max-height: 480px) {
      display: none;
    }

    @media screen and (max-width: 481px) {
      font-size: 14px;
    }
  }

  .social-block {
    display: flex;
    width: 100%;

    img {
      width: 20px;
      margin-right: 15px;
      @media screen and (max-width: 481px) {
        width: 16px;
      }
    }
    @media screen and (max-height: 480px) {
      display: none;
    }
  }
}

.contact-active {
  right: 0;
  @media screen and (max-width: 481px) {
    left: 0;
  }
}
