// .solutions {
//   visibility: hidden;
//   opacity: 0;
//   transition: visibility 0s, opacity 1s linear;

//   .solutions-desc {
//     color: white;
//     position: fixed;
//     top: 40%;
//     pointer-events: none;
//     // width: 45vw;

//     .solutions-description {
//       width: 40%;
//     }

//     .text-right {
//       text-align: right;
//       pointer-events: none;
//     }
//   }

//   .right {
//     right: 200px;
//     pointer-events: none;
//   }

//   .left {
//     left: 120px;
//     pointer-events: none;
//   }

//   .lastSlide {
//     position: absolute;
//     width: 51%;
//     height: 50%;
//     left: 25%;
//     top: 25%;

//     .item {
//       position: absolute;
//       top: 54%;
//       right: 0;
//       transform: translateY(-50%);
//       min-width: 150px;
//       width: 25%;
//       max-width: 300px;
//       pointer-events: all;
//       color: white;
//       text-decoration: none;
//       text-transform: uppercase;
//       cursor: pointer;

//       .logo-link {
//         display: block;
//         width: 100%;
//         height: auto;
//         animation: fadein 3s linear;
//         transition: transform 0.3s ease-in-out;

//         &:hover {
//           transform: scale(1.2);
//         }
//       }
//     }
//   }

//   .solutions-mobile {
//     width: 100%;
//     display: none;
//     position: absolute;
//     top: 55vh;
//     left: 0;
//     right: 0;
//     z-index: 100;

//     h1 {
//       font-size: 25px;
//       color: white;
//       font-family: "Segoe UI Bold";
//       font-style: normal;
//       font-weight: normal;
//     }

//     .solutions-description {
//       width: 70%;
//       text-align: center;
//       font-size: 18px;
//     }
//   }

//   .active {
//     display: flex;
//     justify-content: center;
//   }

//   .visible {
//     top: 0px !important;

//     // those two cannot be used because of bug. Changing top value solves that bug.
//     // display: block !important;
//     // visibility: visible !important;
//   }

//   .png-player {
//     position: absolute;
//     overflow: hidden;
//   }

//   .desktop {
//     width: calc(100vh * 1.77777777777777);
//     height: 100%;
//     top: 0;
//     left: 50%;
//     transform: translate(-50%, 0);

//     .animation {
//       position: absolute;
//       top: 100%;
//       height: 100%;
//     }

//     .to-solution {
//       animation: to-solution-anim 0.833s steps(19) 1;
//       animation-fill-mode: both;
//     }

//     .to-cybersecurity {
//       animation: to-cybersecurity-anim 0.667s steps(15) 1;
//       animation-fill-mode: both;
//     }

//     .to-managed {
//       animation: to-managed-anim 0.666s steps(15) 1;
//       animation-fill-mode: both;
//     }

//     .to-professional {
//       animation: to-professional-anim 0.792s steps(18) 1;
//       animation-fill-mode: both;
//     }

//     .fade-out {
//       animation: fade-out-anim 0.375s steps(8) 1;
//       animation-fill-mode: both;
//     }

//     .revert-anim {
//       animation-direction: reverse;
//     }

//     @keyframes to-solution-anim {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-95%);
//       }
//     }

//     @keyframes to-cybersecurity-anim {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-93.75%);
//       }
//     }

//     @keyframes to-managed-anim {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-93.75%);
//       }
//     }

//     @keyframes to-professional-anim {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-94.73684210526316%);
//       }
//     }

//     @keyframes fade-out-anim {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-88.88888888888889%);
//       }
//     }
//   }

//   .mobile {
//     width: 100vw;
//     height: 100vh;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     .animation {
//       position: absolute;
//       top: 100%;
//       height: 100%;
//       left: calc(50vw - 100vh * 0.5625 * 0.5);
//     }

//     .to-solution {
//       animation: to-solution-anim-m 0.5s steps(7) 1;
//       animation-fill-mode: both;
//     }

//     .to-cybersecurity {
//       animation: to-cybersecurity-anim-m 0.5s steps(14) 1;
//       animation-fill-mode: both;
//     }

//     .to-managed {
//       animation: to-managed-anim-m 0.5s steps(8) 1;
//       animation-fill-mode: both;
//     }

//     .to-professional {
//       animation: to-professional-anim-m 0.5s steps(9) 1;
//       animation-fill-mode: both;
//     }

//     .fade-out {
//       animation: fade-out-anim-m 0.5s steps(7) 1;
//       animation-fill-mode: both;
//     }

//     .revert-anim {
//       animation-direction: reverse;
//     }

//     @keyframes to-solution-anim-m {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-87.5%);
//       }
//     }

//     @keyframes to-cybersecurity-anim-m {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-93.33333333333333%);
//       }
//     }

//     @keyframes to-managed-anim-m {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-88.88888888888889%);
//       }
//     }

//     @keyframes to-professional-anim-m {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-90%);
//       }
//     }

//     @keyframes fade-out-anim-m {
//       from {
//         transform: translateX(0);
//       }

//       to {
//         transform: translateX(-87.5%);
//       }
//     }
//   }

//   // #cybersecurityServices,
//   // #professionalServices,
//   // #managedServices {
//   //   width: 67vw;

//   //   h1 {
//   //     width: 100%;
//   //   }
//   // }

//   .solutions-mobile#solutions {
//     top: 55vh;
//     flex-wrap: wrap;
//     flex-direction: column;
//     width: 100%;
//     align-items: center;
//   }
// }
/////// solutions -- kod landskape monitora kad udari skrol, krece animacija iz perspektive, kad udari drugi skrol stari ide na fejd out sledeci ulazi
///////	biznis -- kad ucita, posle dve sekunde pojavlju se prvi tekst iz perspektive, a tu i ostaje kad se skroluje na ekranu kako jeste
.solutions-mother{
	width: 100%;
	overflow: hidden;
}

.solutions-texts {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;

	.solutions-box {
		width: auto;
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.solutions-paragraph {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {

	.solutions-texts {
		/* adjust positioning as needed */
		position: absolute;
		top: 30vh;
		left: 4vh;
		pointer-events: none;

		.solutions-box {
			width: auto;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.solutions-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 34px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}

.solutions-texts2 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;

	.solutions-box2 {
		width: auto;
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.solutions-paragraph2 {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {

	.solutions-texts2 {
		position: absolute;
		top: 20vh;
		left: 4vh;
		pointer-events: none;


		.solutions-box2 {
			width: auto;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.solutions-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 34px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}


.solutions-texts3 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;

	.solutions-box3 {
		width: auto;
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.solutions-paragraph3 {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {

	.solutions-texts3 {
		/* adjust positioning as needed */
		position: absolute;
		top: 25vh;;
		left: 4vh;
		pointer-events: none;

		.solutions-box3 {
			width: auto;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.solutions-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}


@media only screen and (min-device-width: 375px) and (max-device-width: 900px) and (orientation: landscape) {
	.solutions-texts {
		position: absolute;
		top: 30vh;
		left: 4vh;
		pointer-events: none;

		.solutions-box {
			width: auto;
			max-width: 20vh;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.solutions-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.solutions-texts2 {
		position: absolute;
		top: 30vh;
		left: 4vh;
		pointer-events: none;


		.solutions-box2 {
			width: auto;
			max-width: 20vh;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.solutions-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.solutions-texts3 {
		/* adjust positioning as needed */
		position: absolute;
		top: 30vh;
		left: 4vh;
		pointer-events: none;

		.solutions-box3 {
			width: auto;
			height: auto;
			max-width: 20vh;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.solutions-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 20px;
			pointer-events: none;
			white-space: nowrap;
		}
	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}