.mainLogo {
  position: absolute;
  left: 3vw;
  top: 3vh;
  color: white;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  width: 8vw;
  max-width: 90px;
  z-index: 500;

  @media screen and (max-height: 480px) {
    left: 2vw;
    top: 3vh;
    width: 6%;
  }

  @media screen and (max-width: 481px) {
    width: 19%;

    top: 2vh;
    transform: translateX(-50%);
    left: 50%;
  }

  .logoImg {
    cursor: pointer;
    display: block;
    width: 100%;
    @media screen and (max-width: 481px) {
      width: 100%;
    }
  }
}

.mobileLogo {
  position: absolute;
  left: 6vw;
  top: 2%;
  color: white;
}
.mobileLogoImg {
  width: 17vw;
}
