
#wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  // background: url("https://user-images.githubusercontent.com/26748614/112761469-c59e9600-8ffb-11eb-8b54-26e9ec69573e.jpg");
  // background-size: cover;
  background-color: rgba(1,1,1,0);
  background-position: 50% 50%;
  pointer-events: none;
}

/* === Button === */
.btn {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn .spinningColors {
  width: 150px;
  height: 150px;
}

.btn .spinningColors span {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  background: #c4edf500;
}

.btn .spinningColors span:nth-child(1) {
  filter: blur(5px);
}

.btn .spinningColors span:nth-child(2) {
  filter: blur(10px);
}

.btn .spinningColors span:nth-child(3) {
  filter: blur(25px);
}

/*This Element animates dynamically in js  (shining sun)*/
.btn .spinningColors span:nth-child(4) {
  filter: blur(50px);
  transform: scale(var(--scale-offset));
}

.btn .badge {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  // cursor: pointer;
  position: absolute;
  top: 0;
  color: #000000;
  background-color: transparent;
}

.btn .badge:hover,
.btn .badge:active {
  /*the duration of this animation is determined dynamically in js*/
  animation: btnScale var(--timeOffset) infinite cubic-bezier(1, 0.42, 0.29, 0.51);
}

@keyframes btnScale {
  0% {
    transform: scale(1.3);
    color: #242424;
  }
  50% {
    transform: scale(1);
    color: #000000;
  }
  100% {
    transform: scale(1.3);
    color: #242424;
  }
}

.gradient{
  opacity: 1;
}

// .btn .btnText {
//   font-family: "Carter One", cursive;
//   font-size: 34px;
//   text-align: center;
//   line-height: 38px;
//   position: absolute;
//   top: 38px;
//   left: 0px;
//   user-select: none;
// }

/* === Gradient === */
.gradient {
  height: 100vh;
  width: 100%;
}