#home {
  opacity: 0;
  transition: opacity 1.2s linear;
  // overflow: hidden;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // position: absolute;
  .homepage-logo {
    position: absolute;
    top: 10%;
    left: 10%;
    color: white;
  }

  .pix {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    z-index: -100;
  }

  #homepage-content {
    // opacity: 0;
  }

  .homepage-slogan-wrapper {
    color: white;
    position: absolute;
    left: 30px;
    right: 0;

    top: calc(43vh);
    display: flex;
    justify-content: center;
    @media screen and (max-width: 481px) {
      left: 0;
    }
    img {
      display: block;
      width: 60%;

      @media screen and (max-width: 481px) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .explore-button {
    width: 200px;
    color: white;
    border: 2px solid white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    padding: 20px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    &:hover {
      color: #fff;
      box-shadow: 0 0 10px #9ecaed;

      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa, 0 0 82px #0fa, 0 0 92px #0fa,
        0 0 102px #0fa, 0 0 151px #0fa;
    }

    @media screen and (max-width: 481px) {
      width: 30%;
      font-size: 14px;
      bottom: 5%;
      padding: 10px;
    }

    @media screen and (max-height: 480px) {
      width: 20%;
      font-size: 13px;
      bottom: 5%;
      padding: 10px 0;
    }
  }

  .frontpagetext {
    position: absolute;
    top: -20px;
    left: 10px;
    color: white;
    display: flex;
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s linear;
  }

  .home-btn-mobile-wrapper {
    width: 100%;
    position: absolute;
    top: 23vh;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .frontpagetext {
      position: unset;
    }
    .home-btn {
      position: unset;
      color: white;
    }
  }

  .swipe-icon-wrapper {
    width: 100%;
    height: 150px;
    position: fixed;
    right: 0;
    bottom: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: white;
    align-items: center;
    z-index: 0;

    p {
      position: fixed;
      bottom: 60px;
      width: 100%;
      text-align: center;
      font-size: 16px;
    }

    img {
      width: 100px;
    }
  }
}
