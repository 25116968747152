* {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: "Segoe UI Regular", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input,
textarea {
  border: none;
  outline: none;
}

#my-canvas {
  /* position: fixed;
  right: 0;
  top: 100; */
  width: 100%;
  height: 100vh;
}

#worker-canvas {
  /* position: fixed;
  right: 0;
  top: 0;
  z-index: 10; */
  /* width: 100%; */
  // /* height: 100vh; */
  //    width: 1000px;
  //  height: 500px;
}

/* temp needed for development purposes */
#fps {
  position: fixed;
  color: black;
  background-color: white;
  right: 0;
  top: 0;
  font-size: 20px;
  font-weight: 600;
}

.app {
  visibility: hidden;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

.left-block {
  color: white;
  position: fixed;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
  width: 30vw;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
}

.right-block {
  color: white;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 180px;
  width: 30vw;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
  text-align: right;
}

.center-block {
  color: white;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 30vw;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
  text-align: center;
}

.card-description {
  color: white;
  font-size: inherit;
  font-family: "Segoe UI Light";
  font-style: normal;
  font-weight: normal;
  display: flex;
}

.bold-large {
  color: white;
  font-size: 46px;
  text-transform: uppercase;
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
}

.text-regular {
  color: white;
  font-size: 20px;
  font-family: "Segoe UI Light";
  font-style: normal;
  font-weight: normal;
}

.wider {
  width: 55vw;
}

.description-narrow {
  width: 65%;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}

// .background-black {
//   // position: absolute;
//   // top: 0;
//   // left: 0;
//   // width: 100%;
//   // height: 100%;
//   // background-color: black;
//   // z-index: -999;
// }

.lastslide-mobile {
  position: fixed;
  width: 100%;
  bottom: 15vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  .item-mobile {
    width: 30%;

    .logo-link-mobile {
      display: block;

      width: 100%;
    }
  }

  .item-mobile-smaller {
    width: 25%;
  }

  .item-mobile-middle {
    width: 40%;
  }

  #ourWork {
    display: none;
  }
}

.lastslide-mobile-center {
  bottom: 50vh;
  transform: translateY(50%);
}

.show-contact-button {
  position: fixed;
  left: -80px;
  top: 10px;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  transform: rotate(90deg) translateX(50%);
  text-transform: uppercase;
  color: white;

  outline: none;
  border: none;
  font-size: 20px;
  z-index: 10000;

  @media screen and (max-height: 481px) {
    left: -60px;
    font-size: 15px;
  }

  @media screen and (max-width: 481px) {
    right: -60px;
    left: unset;
    font-size: 15px;
  }
}

.show-contact-button-open {
  position: fixed;
  left: unset;
  right: -20px;
  top: 10px;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  transform: rotate(90deg) translateX(50%);
  text-transform: uppercase;
  color: white;

  outline: none;
  border: none;
  font-size: 20px;
  z-index: 10000;

  @media screen and (max-height: 481px) {
    font-size: 15px;
  }

  @media screen and (max-width: 481px) {
    left: unset;
    right: -15px;
    font-size: 15px;
  }
}

body {
  margin: 0;
  font-family: "Segoe UI Light", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}

h1,
p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#my-canvas {
  position: absolute;
  // width: 110vw;
  // height: 110vh;
  // top: -5%;
  // left: -5%;
}

#worker-canvas {
  /* position: fixed;
  right: 0;
  top: 0;
  z-index: 10; */
  /* width: 100%; */
  // /* height: 100vh; */
  //    width: 1000px;
  //  height: 500px;
}

/* temp needed for development purposes */
#fps {
  position: fixed;
  color: black;
  background-color: white;
  right: 10px;
  top: 10px;
  z-index: 300;
  font-size: 20px;
  font-weight: 600;
}

.left-block {
  color: white;
  position: fixed;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
  width: 30vw;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
}

.right-block {
  color: white;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 180px;
  width: 30vw;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
  text-align: right;
}

.center-block {
  color: white;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 30vw;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s linear;
  text-align: center;
}

.bold-large {
  color: white;
  font-size: 36px;
  text-transform: uppercase;
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;

  @media screen and (max-width: 481px) {
    font-size: 26px;
  }
}

.text-regular {
  color: white;
  font-size: 24px;
  font-family: "Segoe UI Light";
  font-style: normal;
  font-weight: normal;
}

.wider {
  width: 55vw;
}

.description-narrow {
  width: 65%;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}

.background-black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -999;
}

.lastslide-mobile {
  position: fixed;
  width: 100%;
  bottom: 15vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  .item-mobile {
    width: 30%;

    .logo-link-mobile {
      display: block;

      width: 100%;
    }
  }

  .item-mobile-smaller {
    width: 25%;
  }

  .item-mobile-middle {
    width: 40%;
  }

  #ourWork {
    display: none;
  }
}

.lastslide-mobile-center {
  bottom: 50vh;
  transform: translateY(50%);
}

@media only screen and (orientation: portrait) {
  .fullscreen-button {
    position: absolute;
    right: 0px;
    bottom: 15px;
    border: 0;
    background-color: transparent;
    z-index: 101;
  }
}

@media only screen and (orientation: landscape) {
  .fullscreen-button {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    border: 0;
    z-index: 101;
    transform: translate(-50%, -110%);
    -webkit-transform: translate(-50%, -110%);
    -moz-transform: translate(-50%, -110%);
    -o-transform: translate(-50%, -110%);
    -ms-transform: translate(-50%, -110%);
  }

  .landscape-overlay {
    z-index: 100;
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .landscape-overlay-text {
      color: white;
      position: absolute;
      top: 55%;
      left: 50%;
      font-size: 18px;
      font-weight: 400;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
    }
  }
}

.box-minmax {
  margin-top: 30px;
  width: 608px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #ffffff;

  span:first-child {
    margin-left: 10px;
  }
}

.rndVideoDiv {
  z-index: 0;
  pointer-events: none;
}

.rndControls {
  z-index: 1;
}

.enableClicks {
  pointer-events: all;
  z-index: 100;
}

.divSun {
  position: absolute;
  top: calc(48% - 280px);
  left: calc(52% - 180px);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
    inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  transform: rotate(45deg);
  animation: rotate 5s linear infinite;
}

.divSun2 {
  position: absolute;
  top: calc(48% - 280px);
  left: calc(20% - 180px);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
    inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  transform: rotate(45deg);
  animation: rotate 5s linear infinite;
}

.divSun3 {
  position: absolute;
  top: calc(35% - 280px);
  left: calc(28% - 180px);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
    inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  transform: rotate(45deg);
  animation: rotate 5s linear infinite;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .divSun {
    left: calc(59% - 180px);
    /* adjust the value as needed */
  }
}

@media only screen and (max-width: 768px) {
  .divSun2,
  .divSun3 {
    display: none;
  }
}

.custom-label-sol {
  color: white;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.margin-N-right {
  top: 15vh;
  right: 25vh;
  z-index: 25;
  position: absolute;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.custom-input-sol {
  --thumb-color: red;
  --thumb-background: white;
  --track-fill: red;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 767px) {
  .form-row {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 819px) and (orientation: portrait) {
  .margin-N-right {
    top: 15vh;
    left: 4vh;
    z-index: 25;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-label-sol {
    margin-left: -5 5px;
    color: white;
    font-size: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-input-sol {
    margin-left: -15px;
    height: 10px;
    width: 220px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media only screen and (min-device-width: 760px) and (max-device-width: 819px) and (orientation: portrait) {
  .margin-N-right {
    top: 15vh;
    left: 4vh;
    z-index: 25;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-label-sol {
    margin-left: -5 5px;
    color: white;
    font-size: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-input-sol {
    margin-left: -15px;
    height: 10px;
    width: 220px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media only screen and (min-device-width: 175px) and (max-device-width: 760px) and (orientation: portrait) {
  .margin-N-right {
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(-7vh);
    z-index: 25;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-label-sol {
    margin-left: -5 5px;
    color: white;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-input-sol {
    margin-left: -15px;
    height: 4px;
    width: 120px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media only screen and (min-device-width: 175px) and (max-device-width: 900px) and (orientation: landscape) {
  .divSun {
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 80px);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
      inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
    transform: rotate(45deg);
    animation: rotate 5s linear infinite;
  }

  .divSun2 {
    position: absolute;
    top: calc(38% - 75px);
    left: calc(20% - 65px);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
      inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
    transform: rotate(45deg);
    animation: rotate 5s linear infinite;
  }

  .divSun3 {
    position: absolute;
    top: calc(25% - 65px);
    left: calc(28% - 65px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f,
      inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
    transform: rotate(45deg);
    animation: rotate 5s linear infinite;
  }

  .margin-N-right {
    top: 15vh;
    right: 25vh;
    z-index: 25;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-label-sol {
    margin-left: -5 5px;
    color: white;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-input-sol {
    margin-left: -15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

//////////////////////

.pixi-canvas {
  position: absolute;
  width: 100vw;
  height: 100vh;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: -10;
}

.infinity {
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  position: fixed;
}

.fixed-fadein {
  opacity: 0.5;
}

.fadein {
  animation: fadein 0.8s ease-in forwards;
}

@keyframes fadein {
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

.fadeout {
  animation: fadeout 1s ease-in forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeout-home {
  animation: fadeout 1.2s ease-in forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.vignette {
  // position: fixed;
  // top: 0;
  // left: 0;
  // background: radial-gradient(transparent 30%, black), transparent;
  // height: 100vh;
  // width: 100vw;
  // z-index: 1001;
  // pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  //background: radial-gradient(transparent 70%, black), transparent;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  pointer-events: none;
}

.container-wrap {
  //   position: absolute;
  //   width: 100vw;
  //   height: 100vh;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   // filter: sepia(150%) contrast(100%) saturate(120%);
  //   filter: contrast(100%) saturate(120%);
  //   // animation: left-right 4s ease-in-out infinite;
}

@keyframes left-right {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -10px;
  }

  100% {
    margin-left: 0;
  }
}
