
.newPixels-texts {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    padding: 10px;
    top: 400px;
    pointer-events: none;
  
    .newPixels-box {
      width: 100%;
      max-width: 400px;
      // adjust height as needed
      height: auto;
      transition: height 0.1s linear;
      pointer-events: none;
    }
  
    .newPixels-paragraph {
      font-family: "Segoe_UI_Semibold";
      margin-bottom: 10px;
      font-size: 18px;
      pointer-events: none;
	  white-space: nowrap;
    }
  }
  
  @media (min-width: 768px) {
  
    /* styles for larger screens */
    .newPixels-texts {
      /* adjust positioning as needed */
      position: absolute;
      top: -20%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
  
      .newPixels-box {
        width: 100%;
        max-width: 400px;
        // adjust height as needed
        height: auto;
        transition: height 0.1s linear;
        pointer-events: none;
      }
  
      .newPixels-paragraph {
        font-family: "Segoe_UI_Semibold";
        margin-bottom: 10px;
        font-size: 17px;
        pointer-events: none;
		white-space: nowrap;
      }
    }
  }

  
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 
.businessNewN-mother{
	position: relative;
}
@media (min-width: 768px) {
	.businessNewN-mother{
		position: inherit;
	}
}

.pixelsNewN-texts {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;
	z-index: 20;

	.pixelsNewN-box {
		width: 100%;
		max-width: 400px;
		// adjust height as needed
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.pixelsNewN-paragraph {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		perspective: 1000px;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {

	.pixelsNewN-texts {
		/* adjust positioning as needed */
		position: absolute;
		top: -10%;
		bottom: 0;
		left: -32%;
		right: 0;
		pointer-events: none;
		z-index: 20;

		.pixelsNewN-box {
			width: 100%;
			max-width: 46%;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.pixelsNewN-paragraph {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 34px;
			pointer-events: none;
			perspective: 1000px;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}

.pixelsNewN-texts2 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;
	z-index: 20;

	.pixelsNewN-box2 {
		width: 100%;
		max-width: 400px;
		// adjust height as needed
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.pixelsNewN-paragraph2 {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {

	.pixelsNewN-texts2 {
		position: absolute;
		top: -23%;
		bottom: 0;
		left: -38%;
		right: 0;
		pointer-events: none;
		z-index: 20;


		.pixelsNewN-box2 {
			width: 100%;
			max-width: 40%;
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.pixelsNewN-paragraph2 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 34px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}

.pixelsNewN-texts3 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	padding: 10px;
	top: 400px;
	pointer-events: none;
	z-index: 20;

	.pixelsNewN-box3 {
		width: 100%;
		max-width: 400px;
		// adjust height as needed
		height: auto;
		transition: height 0.1s linear;
		pointer-events: none;
	}

	.pixelsNewN-paragraph3 {
		font-family: "Segoe_UI_Semibold";
		margin-bottom: 10px;
		font-size: 18px;
		pointer-events: none;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {

	.pixelsNewN-texts3 {
		/* adjust positioning as needed */
		position: absolute;
		top: 25%;
		bottom: 0;
		left: -25%;
		right: 0;
		pointer-events: none;
		z-index: 20;

		.pixelsNewN-box3 {
			width: 100%;
			max-width: 55%;
			// adjust height as needed
			height: auto;
			transition: height 0.1s linear;
			pointer-events: none;
		}

		.pixelsNewN-paragraph3 {
			font-family: "Segoe_UI_Semibold";
			margin-bottom: 10px;
			font-size: 37px;
			pointer-events: none;
			white-space: nowrap;
		}

	}

	.scale {
		animation: scale linear 0.5s forwards;
	}

	.unscale {
		animation: unscale linear 0.5s forwards;
	}

	@keyframes scale {
		0% {
			width: 100px;
		}

		100% {
			width: 600px;
		}
	}

	@keyframes unscale {
		0% {
			width: 600px;
		}

		100% {
			width: 100px;
		}
	}
}

.pixelsNewN-paragraph .letter {
	display: inline-block;
	opacity: 0; 
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
	opacity 1s ease-out;
  }
  
  .pixelsNewN-paragraph .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes jump-in {
	from {
	  transform: translate3d(0px, 50px, -100px);
	  opacity: 0;
	}
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  ////
  /// 
.pixelsNewN-paragraph2 .letter {
	display: inline-block;
	opacity: 0; 
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
	opacity 1s ease-out;
  }
  
  .pixelsNewN-paragraph2 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes jump-in {
	from {
	  transform: translate3d(0px, 50px, -100px);
	  opacity: 0;
	}
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  ////
.pixelsNewN-paragraph3 .letter {
	display: inline-block;
	opacity: 0; 
	transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1),
	opacity 1s ease-out;
  }
  
  .pixelsNewN-paragraph3 .letter.animated {
	animation-name: jump-in;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  
  @keyframes jump-in {
	from {
	  transform: translate3d(0px, 50px, -100px);
	  opacity: 0;
	}
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }