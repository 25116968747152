.our-work-contact {
  background-color: white;
  width: 100%;
  padding: 2em 2em 2em 2em;

  @media screen and (max-width: 481px) {
    padding: 10px;
    width: 100%;
    right: -100%;
  }

  .our-work-contact-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    @media screen and (max-width: 768px) {
      height: 200px;
    }

    .our-work-contact-header-title {
      font-size: 40px;
      text-transform: uppercase;
      color: #000;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .our-work-contact-header-description {
      font-size: 18px;
      font-weight: 400;
      width: 40%;
      text-align: center;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    pointer-events: none;
  }

  .contact-block {
    width: 100%;
    display: flex;
    justify-content: center;
    color: black;
    pointer-events: none;

    @media screen and (max-width: 481px) {
      width: 100%;
    }

    input,
    textarea,
    button,
    a {
      pointer-events: all;
    }
  }

  .description {
    font-size: 16px;

    margin-bottom: 20px;

    @media screen and (max-width: 481px) {
      width: 90%;
      font-size: 14px;
    }
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    ::placeholder {
      font-family: "Segoe UI Light";
    }

    @media screen and (max-width: 481px) {
      width: 90%;
    }

    .input-wrapper {
      width: 100%;
      margin-bottom: 7px;
      padding-bottom: 7px;
      position: relative;
      @media screen and (max-width: 481px) {
      }
    }

    input,
    textarea {
      outline: none;
      display: block;
      box-sizing: border-box;
      width: 100%;

      border: 2px solid black;
      padding: 12px 22px;

      color: black;
      font-family: "Segoe UI Light";
      font-size: 14px;
      @media screen and (max-width: 481px) {
        padding: 6px 11px;
        font-size: 10px;
      }
    }

    .validation-error {
      color: rgb(217, 93, 31);
      font-size: 10px;
      margin: 0;
      position: absolute;
      bottom: -5px;
      left: 20px;
      font-weight: 600;
    }

    .contact-button {
      width: 30%;
      padding: 20px 20px;
      border: none;
      outline: none;
      border-radius: 30px;
      font-size: 16px;
      text-transform: capitalize;
      cursor: pointer;
      color: white;
      font-weight: 600;
      text-transform: capitalize;
      background-color: black;
      @media screen and (max-width: 481px) {
        width: 120px;
        font-size: 10px;
      }
    }
  }

  .contact-block-data {
    .contact-block-data-item {
      text-decoration: none;

      color: white;
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;
      font-family: "Segoe UI Light";
      p {
        display: inline-block;
        margin-left: 15px;
      }

      img {
        display: inline-block;
        width: 20px;
        height: auto;
        @media screen and (max-width: 481px) {
          width: 14px;
        }
      }
    }

    @media screen and (max-width: 481px) {
      font-size: 14px;
    }
  }

  .social-block {
    display: flex;
    width: 100%;

    img {
      width: 20px;
      margin-right: 15px;
      @media screen and (max-width: 481px) {
        width: 16px;
      }
    }
  }
}
