.overlay {
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: absolute;
    pointer-events: none;
    cursor: auto;
    overflow: hidden;
  }
  
  .big-circle {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    // animation: fadein 0.15s ease-in forwards;
    animation-fill-mode: forwards; 
    pointer-events: all;
    cursor: auto;
  }
  
//   @keyframes fadein {
//     from {
//       opacity: 0;
//     }
  
//     to {
//       opacity: 1;
//     }
//   }
  .circle-hidden {
    // visibility: 0;
    opacity: 0;
    pointer-events: none;
  }
  
  .circle1 {
    background-color: rgb(204, 249, 255);
    //animation-delay: 0.05s;
    width: 200px;
    height: 200px;
  }
  
  .circle2 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 47%, rgb(191, 243, 252) 47%);
    //animation-delay: 0.1s;
    width: 300px;
    height: 300px;
  }
  
  .circle3 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 52.9%, rgb(178, 237, 249)52.9%);
    //animation-delay: 0.15s;
    width: 400px;
    height: 400px;
  }
  
  .circle4 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 56.4%, rgb(165, 231, 246)56.4%);
    //animation-delay: 0.2s;
    width: 500px;
    height: 500px;
  }
  
  .circle5 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 58.8%, rgb(152, 224, 243) 58.8%);
    //animation-delay: 0.25s;
    width: 600px;
    height: 600px;
  }
  
  .circle6 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 60.5%, rgb(139, 218, 240)60.5%);
    //animation-delay: 0.3s;
    width: 700px;
    height: 700px;
  }
  
  .circle7 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 61.8%, rgb(126, 212, 237)61.8%);
    //animation-delay: 0.35s;
    width: 800px;
    height: 800px;
  }
  
  .circle8 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 62.8%, rgb(113, 206, 234)62.8%);
    //animation-delay: 0.4s;
    width: 900px;
    height: 900px;
  }
  
  .circle9 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 63.55%, rgb(100, 200, 231) 63.55%);
    //animation-delay: 0.45s;
    width: 1000px;
    height: 1000px;
  }
  
  .circle10 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 64.22%, rgb(87, 193, 228)64.22%);
    //animation-delay: 0.5s;
    width: 1100px;
    height: 1100px;
  }
  
  .circle11 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 64.75%, rgb(74, 187, 225) 64.75%);
    //animation-delay: 0.55s;
    width: 1200px;
    height: 1200px;
  }
  
  .circle12 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 65.21%, rgb(61, 181, 222)  65.21%);
    //animation-delay: 0.6s;
    width: 1300px;
    height: 1300px;
  }
  
  .circle13 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 65.61%, rgb(48, 175, 219)  65.61%);
    //animation-delay: 0.65s;
    width: 1400px;
    height: 1400px;
  }
  
  .circle14 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 65.95%, rgb(35, 168, 216)  65.95%);
    //animation-delay: 0.7s;
    width: 1500px;
    height: 1500px;
  }
  
  .circle15 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 66.2%, rgb(22, 162, 213) 66.2%);
    //animation-delay: 0.75s;
    width: 1600px;
    height: 1600px;
  }
  
  .circle16 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 66.52%, rgb(9, 156, 210)  66.52%);
    //animation-delay: 0.8s;
    width: 1700px;
    height: 1700px;
  }
  
  .circle17 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 66.73%, rgb(6, 135, 182) 66.73%);
    //animation-delay: 0.85s;
    width: 1800px;
    height: 1800px;
  }
  
  .circle18 {
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 66.8%, rgb(4, 114, 155)  66.8%);
    //animation-delay: 0.9s;
    width: 1900px;
    height: 1900px;
  }
  
  .circle19 {
    background-color: rgba(0, 0, 0, 1);
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 66.92%, rgb(3, 93, 128)  66.92%);
    //animation-delay: 0.95s;
    width: 2000px;
    height: 2000px;
  }
  
  .circle20 {
    background-color: rgba(0, 0, 0, 1);
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 54.1%, rgba(2, 72, 101, 1)  54.1%);
    //animation-delay: 1s;
    width: 2600px;
    height: 2600px;
  }
  
  @media screen and (max-width: 900px) {
  
    .circle1 {
      background-color: rgb(204, 249, 255);
      //animation-delay: 0.05s;
      width: 150px;
      height: 150px;
    }
    
    .circle2 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 42%, rgb(191, 243, 252)42%);
      //animation-delay: 0.1s;
      width: 250px;
      height: 250px;
    }
    
    .circle3 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 50%, rgb(178, 237, 249)50%);
      //animation-delay: 0.15s;
      width: 350px;
      height: 350px;
    }
    
    .circle4 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 54.2%, rgb(165, 231, 246)54.2%);
      //animation-delay: 0.2s;
      width: 450px;
      height: 450px;
    }
    
    .circle5 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 57.6%, rgb(152, 224, 243)57.6%);
      //animation-delay: 0.25s;
      width: 550px;
      height: 550px;
    }
    
    .circle6 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 59.5%, rgb(139, 218, 240)59.5%);
      //animation-delay: 0.3s;
      width: 650px;
      height: 650px;
    }
    
    .circle7 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 61.1%, rgb(126, 212, 237)61.1%);
      //animation-delay: 0.35s;
      width: 750px;
      height: 750px;
    }
    
    .circle8 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 62.2%, rgb(113, 206, 234)62.2%);
      //animation-delay: 0.4s;
      width: 850px;
      height: 850px;
    }
    
    .circle9 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 63.15%, rgb(100, 200, 231) 63.15%);
      //animation-delay: 0.45s;
      width: 950px;
      height: 950px;
    }
    
    .circle10 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 63.82%, rgb(87, 193, 228)63.82%);
      //animation-delay: 0.5s;
      width: 1050px;
      height: 1050px;
    }
    
    .circle11 {
      background: radial-gradient(circle at center, rgba(255, 0, 255, 0) 0%, rgba(255, 0, 255, 0) 64.25%, rgb(74, 187, 225)  64.25%);
      //animation-delay: 0.55s;
      width: 1150px;
      height: 1150px;
    }
  }
  
  // .circle1 {
  //   background-color: #e0a51f;
  //   //animation-delay: 0.05s;
  //   width: 200px;
  //   height: 200px;
  // 	animation: pulsate 1s ease-out;
  // 	animation-iteration-count: infinite;
  // }
  // @keyframes pulsate {
  // 	0% {
  // 		transform: scale(1);
  // 		opacity: 1;
  // 	}
  
  // 	50% {
  // 		transform: scale(1.5);
  // 		opacity: 1;
  // 	}
  
  // 	100% {
  // 		transform: scale(1);
  // 		opacity: 1;
  // 	}
  // }
  
  /// FADOUT
  .big-circle2 {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    opacity: 1;
    // animation: fadeout 1s ease-in forwards;
    pointer-events: none;
    cursor: auto;
    overflow: hidden;
  }
  
//   @keyframes hide-circle {
//     0% {
//       opacity: 1;
//     }
  
//     100% {
//       opacity: 0;
//     }
//   }
  
  .circleFadeOut {
    // animation-name: hide-circle;
    // animation-duration: 0.15s; 
    // animation-fill-mode: forwards;
    // opacity: 1;
  }
  
  .circleFadeOut:nth-child(20) {
    //animation-delay: 0s;
  }
  
  .circleFadeOut:nth-child(19) {
    //animation-delay: 0.1s;
  }
  
  .circleFadeOut:nth-child(18) {
    //animation-delay: 0.2s;
  }
  
  .circleFadeOut:nth-child(17) {
    //animation-delay: 0.3s;
  }
  
  .circleFadeOut:nth-child(16) {
    //animation-delay: 0.4s;
  }
  
  .circleFadeOut:nth-child(15) {
    //animation-delay: 0.5s;
  }
  
  .circleFadeOut:nth-child(14) {
    //animation-delay: 0.6s;
  }
  
  .circleFadeOut:nth-child(13) {
    //animation-delay: 0.7s;
  }
  
  .circleFadeOut:nth-child(12) {
    //animation-delay: 0.8s;
  }
  
  .circleFadeOut:nth-child(11) {
    //animation-delay: 0.9s;
  }
  
  .circleFadeOut:nth-child(10) {
    //animation-delay: 1s;
  }
  
  .circleFadeOut:nth-child(9) {
    //animation-delay: 1.1s;
  }
  
  .circleFadeOut:nth-child(8) {
    //animation-delay: 1.2s;
  }
  
  .circleFadeOut:nth-child(7) {
    //animation-delay: 1.3s;
  }
  
  .circleFadeOut:nth-child(6) {
    //animation-delay: 1.4s;
  }
  
  .circleFadeOut:nth-child(5) {
    //animation-delay: 1.5s;
  }
  
  .circleFadeOut:nth-child(4) {
    //animation-delay: 1.6s;
  }
  
  .circleFadeOut:nth-child(3) {
    //animation-delay: 1.7s;
  }
  
  .circleFadeOut:nth-child(2) {
    //animation-delay: 1.8s;
  }
  
  .circleFadeOut:nth-child(1) {
    //animation-delay: 1.9s;
  }