section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: black;
    animation: animateColor 8s linear infinite;
}
@keyframes animateColor {
0% {
    filter: hue-rotate(0deg);
}
100% {
    filter: hue-rotate (360deg);
}
}
section .containerX {
    display: flex;
}
section .containerX .circleX {
position: relative;
width: 150px;
height: 150px;
margin: 0 -7.5px;
}
section .containerX .circleX span {
    position: absolute;
top: 0;
left: 0;
width: 100%;
height:100%;
transform: rotate(calc(18deg * var(--i)))
/* 360 / 20 = 18deg */
}
section .containerX .circleX span::before {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 7.5px);
    width: 15px;
    border-radius: 50%;
height: 15px;
background: rgb(74, 187, 225);
border-radius: 50%
box-shadow 0px 0px 10px rgb(74, 187, 225)
0px 0px 20px rgb(74, 187, 225),
0px 0px 40px rgb(74, 187, 225),
0px 0px 60px rgb(74, 187, 225),
0px 0px 80px rgb(74, 187, 225),
0px 0px 100px rgb(74, 187, 225);
filter: blur(1.4px);
transform: scale(0.1);
animation: animate 4s linear infinite;
animation-delay: calc(0.1s * var(--i))
}
@keyframes animate {
    0% {
        transform: scale(1);
    }
    50%,100% {
        transform: scale(0.1);
    }
}
section .containerX .circleX:nth-child(2) {
    transform: rotate(-180deg);
}
section .containerX .circleX:nth-child(2) span:before {
    animation-delay: calc(-0.1s * var(--i))
}